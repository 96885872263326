import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@mui/lab';
import { Typography, Paper, Box, Stack } from '@mui/material';
import { alpha } from '@mui/system';
import DateIcon from 'src/assets/illustrations/plate/DateIcon';
import Iconify from 'src/components/iconify/Iconify';
import { fDateTime } from 'src/utils/formatTime';

type TimelineType = {
  key: string | undefined;
  title: string;

  time: string;
  color?:
    | 'primary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'inherit'
    | 'grey'
    | 'secondary';
  icon: React.ReactElement;
};

type Props = {
  timLine: (string | undefined)[];
};

export default function ViolationTimeLine({ timLine }: Props) {
  const timelineDate: TimelineType[] | undefined = timLine?.map(
    (item, index) => {
      if (index === 0) {
        return {
          key: item,
          title: 'First Scan Timestamp',
          time: fDateTime(item),
          color: 'info',
          icon: <Iconify icon="eva:clock-outline" width={18} />,
        };
      } else {
        return {
          key: item,
          title: 'Current Scan Timestamp',
          time: fDateTime(item),
          color: 'info',
          icon: <Iconify icon="eva:plus-outline" width={18} />,
        };
      }
    }
  );

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      spacing={2}
    >
      <DateIcon sx={{ width: 30 }} />
      <Timeline position="alternate">
        {timelineDate?.map((item) => (
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              {item.time}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot color={item.color}>{item.icon}</TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography variant="subtitle2" sx={{ whiteSpace: 'no-wrap' }}>
                {item.title}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Stack>
  );
}
