import { Typography, Stack } from '@mui/material';
import Image from '../../components/image';
import {
  StyledRoot,
  StyledSectionBg,
  StyledSection,
  StyledContent,
} from './styles';
import useResponsive from 'src/hooks/useResponsive';
import { Box } from '@mui/system';

type Props = {
  title?: string;
  illustration?: string;
  children: React.ReactNode;
};

export default function LoginLayout({ children, illustration, title }: Props) {
  const isMobile = useResponsive('down', 'sm');

  return (
    <StyledRoot>
      <StyledSection sx={{ position: 'relative' }}>
        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={illustration || '/assets/illustrations/rta-scan-car.jpeg'}
          sx={{ objectFit: 'contain' }}
        />

        <StyledSectionBg />
      </StyledSection>

      <StyledContent>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
