import { Stack } from '@mui/material';
import { useEffect } from 'react';
import useActiveLink from 'src/hooks/useActiveLink';
import { getIncidentCount } from 'src/redux/slices/incident';
import { useDispatch, useSelector } from 'src/redux/store';

import NavItem from './NavItem';

export default function DashboardTab() {
  const REFRESH_TIME = 60000;
  const dispatch = useDispatch();
  useEffect(() => {}, [dispatch]);
  useEffect(() => {
    dispatch(getIncidentCount());

    const interval = setInterval(() => {
      dispatch(getIncidentCount());
    }, REFRESH_TIME);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);
  const { count } = useSelector((state) => state.incident);
  const incidentTotal = count?.toString() ? count?.toString() : '';
  const { active: isAppActive } = useActiveLink('/dashboard/app');

  return (
    <Stack direction="row">
      <NavItem
        path="/dashboard/new-violations"
        text="Review new violations"
        isActive={isAppActive}
      />
      <NavItem
        path="/dashboard/new-incidents"
        text="Incidents"
        extraData={incidentTotal}
      />
    </Stack>
  );
}
