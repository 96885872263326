import { memo } from 'react';
import { Box, BoxProps } from '@mui/material';

function LocationIcon({ ...other }: BoxProps) {
  return (
    <Box {...other}>
        <img
          src="/assets/icons/carInfo/ic-location.svg"
          alt='location'
        />
    </Box>
  );
}

export default memo(LocationIcon);
