export const VIOLATION_STATUS = {
  NEW: 'NEW',
  FELLOW_UP: 'FELLOW_UP',
  FOLLOW_UP: 'FOLLOW_UP',
  NO_VIOLATION: 'NO_VIOLATION',
  FOLLOW_UP_INCIDENT: 'FOLLOW_UP_INCIDENT',
  ARCHIVED: 'ARCHIVED',
  SEND_TO_RTA: 'SEND_TO_RTA',
  VEHICLE_NOT_PRESENT: 'VEHICLE_NOT_PRESENT',
  DRIVER_IN_CAR: 'DRIVER_IN_CAR',
  VEHICLE_ALLOWED: 'VEHICLE_ALLOWED',
  TO_FOLLOW_UP: 'TO_FOLLOW_UP',
  TO_POST_FINE: 'TO_POST_FINE',
  CLOSED_DUE_TIME: 'CLOSED_DUE_TIME',
  PAID_AFTER_SCAN_TIME: 'PAID_AFTER_SCAN_TIME',
  SKIPPED_DUE_DOUBLE_SCAN: 'SKIPPED_DUE_DOUBLE_SCAN',
  SKIPPED_DUE_FINNED_SAME_DAY_SAME_AREA:
    'SKIPPED_DUE_FINNED_SAME_DAY_SAME_AREA',
  SKIPPED_DUE_PREVIOUSLY_FINNED: 'SKIPPED_DUE_PREVIOUSLY_FINNED',
  SKIPPED_DUE_LOW_CONFIDENCE_LEVEL: 'SKIPPED_DUE_LOW_CONFIDENCE_LEVEL',
  SKIPPED_DUE_SIMILAR_EMIRATES: 'SKIPPED_DUE_SIMILAR_EMIRATES',
  ILLEGAL_PARKING: 'ILLEGAL_PARKING',
  LATE_TRANSACTION: 'LATE_TRANSACTION',
  UNCLEAR_IMAGES: 'UNCLEAR_IMAGES',
  MOVING_VEHICLE: 'MOVING_VEHICLE',
  NOT_A_VEHICLE: 'NOT_A_VEHICLE',
  RESERVED_OR_UNCONTROLLED_PARKING: 'RESERVED_OR_UNCONTROLLED_PARKING',
  NO_VIOLATION_AT_SCAN_TIME: 'NO_VIOLATION_AT_SCAN_TIME',
  JOB_ERROR: 'JOB_ERROR',
  SKIPPED_DUE_MISSING_PARKING: 'SKIPPED_DUE_MISSING_PARKING',
  PLATE_DETAILS_NOT_FOUND: 'PLATE_DETAILS_NOT_FOUND',
  PARKING_FINE_SYSTEM_ERROR: 'PARKING_FINE_SYSTEM_ERROR',
  IMAGE_ERROR: 'IMAGE_ERROR',
};
