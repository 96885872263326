import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { IFollowUpViolation } from 'src/@types/followUpViolation';

const initialState: IFollowUpViolation = {
  isLoading: false,
  error: null,
  followUpViolations: [],
  violation: null,
};

const slice = createSlice({
  name: 'follow-up-violation',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getFollowUpViolations(state, action) {
      state.isLoading = false;
      const followUpList = action.payload;
      state.followUpViolations = followUpList;
    },
    getFollowUpViolationById(state, action) {
      state.isLoading = false;
      state.violation = action.payload;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

export function getFollowUpViolations() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/parking-violation/follow-up');
      dispatch(slice.actions.getFollowUpViolations(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFollowUpViolationById(id: string | undefined) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/parking-violation/follow-up/${id}`
      );
      dispatch(slice.actions.getFollowUpViolationById(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
