import * as _ from 'lodash';
export const plateTypes = [
  {
    isEnabled: true,
    label: 'Private',
    id: '1',
  },
  {
    isEnabled: true,
    label: 'Motor Cycle',
    id: '9',
  },
  {
    isEnabled: false,
    label: 'Export',
    id: '14',
  },
  {
    isEnabled: true,
    label: 'Consulate',
    id: '18',
  },
  {
    isEnabled: true,
    label: 'Classic',
    id: '36',
  },
  {
    isEnabled: true,
    label: 'Taxi',
    id: '2',
  },
  {
    isEnabled: false,
    label: 'G/T',
    id: '4',
  },
  {
    isEnabled: false,
    label: 'Commercial',
    id: '3',
  },
  {
    isEnabled: false,
    label: 'Diplomat',
    id: '17',
  },

  {
    isEnabled: false,
    label: 'General organization',
    id: '33',
  },
  {
    isEnabled: false,
    label: 'Public transportation',
    id: '34',
  },
  {
    isEnabled: false,
    label: 'Public 2',
    id: '35',
  },
  {
    isEnabled: false,
    label: 'Trailer',
    id: '37',
  },
  {
    isEnabled: false,
    label: 'Under test',
    id: '16',
  },
  {
    isEnabled: false,
    label: 'Trailer',
    id: '37',
  },

  {
    isEnabled: false,
    label: 'Learning',
    id: '27',
  },
  {
    isEnabled: false,
    label: 'Private transport',
    id: '5',
  },
  {
    isEnabled: false,
    label: 'Political',
    id: '19',
  },
  {
    isEnabled: false,
    label: 'Police',
    id: '24',
  },

  {
    isEnabled: false,
    label: 'Temporary',
    id: '15',
  },
  {
    isEnabled: false,
    label: 'Private Bus',
    id: '7',
  },
  {
    isEnabled: false,
    label: 'General Bus',
    id: '6',
  },
  {
    isEnabled: false,
    label: 'Heavy Equipment',
    id: '8',
  },
  {
    isEnabled: false,
    label: 'Custom',
    id: '28',
  },
  {
    isEnabled: false,
    label: 'Haj',
    id: '31',
  },
  {
    isEnabled: false,
    label: 'Government',
    id: '22',
  },
  {
    isEnabled: false,
    label: 'Emir Court',
    id: '25',
  },
  {
    isEnabled: false,
    label: 'Temporary Customs Entrance',
    id: '13',
  },
  {
    isEnabled: false,
    label: 'Constructions',
    id: '26',
  },
  {
    isEnabled: false,
    label: 'Rent a car',
    id: '11',
  },
  {
    isEnabled: false,
    label: 'Temporary Checking',
    id: '12',
  },
  {
    isEnabled: false,
    label: 'United Nations',
    id: '23',
  },
  {
    isEnabled: false,
    label: 'Tractor',
    id: '10',
  },
  {
    isEnabled: false,
    label: 'C.D',
    id: '20',
  },
  {
    isEnabled: false,
    label: 'Government(GOV)',
    id: '21',
  },
];

export const COUNTRIES = [
  {
    id: 'UAE_AZ',
    label: 'Abu Dhabi',
    plateSource: '1',
    // all list
    // types: ['1', '2', '4', '3', '17', '19', '14', '33', '34', '35', '37'],
    types: ['1', '2', '4', '3', '17', '19', '14', '33', '34', '35', '37'],
  },
  {
    id: 'UAE_DU',
    label: 'Dubai',
    plateSource: '2',
    types: ['1', '2', '4', '3', '18', '9', '14', '27', '36', '37'],
  },
  {
    id: 'UAE_AJ',
    label: 'Ajman',
    plateSource: '4',
    types: ['1', '2', '4', '3', '9', '14', '16', '34'],
  },
  {
    id: 'UAE_SH',
    label: 'Sharjah',
    plateSource: '3',
    types: ['1', '2', '4', '3', '9', '14', '34', '36'],
  },
  {
    id: 'UAE_RK',
    label: 'Ras Al Khaimah',
    plateSource: '6',
    types: ['1', '2', '4', '3', '9', '22', '14', '16'],
  },
  {
    id: 'UAE_FU',
    label: 'Fujairah',
    plateSource: '7',
    types: ['1', '2', '4', '3', '9', '14', '16'],
  },
  {
    id: 'UAE_UQ',
    label: 'Umm Al Quwain',
    plateSource: '5',
    types: ['1', '2', '4', '3', '9', '14', '27', '16'],
  },
  {
    id: 'BRN',
    label: 'Bahrain',
    plateSource: '10',
    types: ['1', '4', '5', '19', '14', '24'],
  },
  { id: 'IRQ', label: 'Iraq', plateSource: '19', types: [1] },
  {
    id: 'KSA',
    label: 'Saudi Arabia',
    plateSource: '8',
    types: [
      '1',
      '2',
      '4',
      '5',
      '18',
      '17',
      '15',
      '7',
      '6',
      '9',
      '14',
      '8',
      '28',
      '31',
    ],
  },
  {
    id: 'KWT',
    label: 'Kuwait',
    plateSource: '11',
    types: ['1', '2', '4', '5', '3', '9', '22', '19', '14', '25', '13', '26'],
  },
  { id: 'RL', label: 'Lebanon', plateSource: '20', types: ['1', '2', '4'] },
  {
    id: 'OM',
    label: 'Oman',
    plateSource: '12',
    types: [
      '1',
      '2',
      '3',
      '11',
      '18',
      '9',
      '22',
      '19',
      '14',
      '27',
      '12',
      '23',
      '10',
    ],
  },
  {
    id: 'Q',
    label: 'Qatar',
    plateSource: '9',
    types: ['1', '2', '4', '5', '18', '20', '14', '21'],
  },
  {
    // TODO id must be different
    id: 'Syria',
    label: 'Syria',
    plateSource: '16',
    types: ['1', '2', '4', 's', '11', '6', '22', '33'],
  },
  {
    // TODO id must be different
    id: 'Yamen',
    label: 'Yamen',
    plateSource: '17',
    types: ['1', '4'],
  },
  {
    // TODO id must be different
    id: 'Jordan',
    label: 'Jordan',
    plateSource: '18',
    types: ['1'],
  },
];

export const findPlateType = (typeId: string) =>
  plateTypes.find(({ id }) => id === typeId);

export const getPlateTypesByCountryId = (countryId: string | undefined) => {
  if (!countryId) {
    return [];
  }

  const countryFound = COUNTRIES.find((country) => country.id === countryId);
  if (!countryFound) {
    return [];
  } else {
    return countryFound.types.map((id) =>
      plateTypes.find((plateType) => plateType.id === id)
    );
  }
};
