import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import {
  RealTimePerformanceState,
} from 'src/@types/performance';

const initialState: RealTimePerformanceState = {
  isLoading: false,
  error: null,
  analytics: null,
};

const slice = createSlice({
  name: 'realTimePerformance',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getRealTimePerformance(state, action) {
      state.isLoading = false;
      state.analytics = action.payload;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

export function getRealTimePerformanceData() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/analytics/real-time');

      dispatch(slice.actions.getRealTimePerformance(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
