import { useState } from 'react';
import { Box } from '@mui/material';
import Image from 'src/components/image/Image';
interface MagnifierProps {
  imgSrc: string;
  imgWidth?: number ;
  imgHeight?: number;
  magnifierRadius: number;
  brightness: number
}

export default function ImageMagnifier({
  imgSrc,
  imgHeight,
  imgWidth,
  magnifierRadius,
  brightness
}: MagnifierProps) {
  // Store the position of the magnifier and position of the large image relative to the magnifier.
  const [magnifierState, setMagnifierState] = useState({
    top: 0,
    left: 0,
    offsetX: 0,
    offsetY: 0,
  });

  // Store whether the magnifier is currently visible.
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 1,
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <Image
          src={imgSrc}
          // Set the intrinsic width of the element (optional).
          width={imgWidth}
          height={imgHeight}
          // Image can be a maximum of 50% of the viewport in either direction.
          sx={{
            maxHeight: 1,
            maxWidth: 1,
            height: 'auto',
            width: 'auto',
            filter: `brightness(${brightness}%)`
          }}
          // Set the magnifier state on every move of the mouse over the image.
          onMouseMove={(e) => {
            setIsVisible(true);
            const smallImage: any = e.currentTarget;
            // mouse position on the small image.
            const x = e.nativeEvent.offsetX;
            const y = e.nativeEvent.offsetY;
            setMagnifierState({
              top: y - magnifierRadius,
              left: x - magnifierRadius,
              // scale up to get position relative to the large image.
              offsetX:
                (x / smallImage.width) * smallImage.naturalWidth -
                magnifierRadius,
              offsetY:
                (y / smallImage.height) * smallImage.naturalHeight -
                magnifierRadius,
            });
          }}
          // Hide the magnifier when leaving the image.
          onMouseLeave={() => setIsVisible(false)}
        />
        <Box
          sx={{
            // Constants:
            boxShadow: '0 5px 10px -2px rgba(0, 0, 0, 0.3)',
            pointerEvents: 'none',
            position: 'absolute',
            border: '2px solid #efefef',
            zIndex: 99,
            display: 'block',
            transition: 'opacity 0.2s',
            // Set background to the image from props:
            background: `url("${imgSrc}") no-repeat #fff`,
            // Set sizing based on the magnifierRadius from props:
            width: 3 * magnifierRadius,
            height: 2 * magnifierRadius,
            // Set position based on on the magnifier state:
            top: magnifierState.top + 'px',
            left: magnifierState.left + 'px',
            backgroundPositionX: -1 * magnifierState.offsetX,
            backgroundPositionY: -1 * magnifierState.offsetY,
            // Toggle opacity based on the isVisible state:
            opacity: isVisible ? 1 : 0,
            filter: `brightness(${brightness}%)`
          }}
        />
      </Box>
    </Box>
  );
}
