const errorMapper = {
  '997:PLATE_STRUCTURE_NOT_FOUND':
    'something wrong with the plate structure please review it',
  incident: 'This is an incident',
};

//@ts-ignore
export const getErrorDescription = (error) => {
  if (!error) {
    return 'error code not found';
  } else {
    //@ts-ignore
    return errorMapper[error];
  }
};
