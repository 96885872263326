function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}
const PARK_VIOLATION_ROOT = '/api/parking-violation';
export const END_POINTS = {
  parkinViolation: {
    list: path(PARK_VIOLATION_ROOT, '/list'),
    history: path(PARK_VIOLATION_ROOT, '/history'),
    statusUpdate: (id: string | undefined) =>
      path(PARK_VIOLATION_ROOT, `/status-update/${id}`),
    postFine: (id: string | undefined) =>
      path(PARK_VIOLATION_ROOT, `/post-fine/${id}`),
    checkCase: (id: string | undefined) =>
      path(PARK_VIOLATION_ROOT, `/check-case/${id}`),
  },
};
