import { Dispatch, createSlice } from '@reduxjs/toolkit';
import { AuditTrailState } from 'src/@types/audit';
import axios from 'src/utils/axios';

const initialState: AuditTrailState = {
  isLoading: false,
  error: null,
  list: [],
  count: 0,
};

const slice = createSlice({
  name: 'audit',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getAuditTrailList(state, action) {
      state.list = action.payload.auditTrailList;
      state.count = action.payload.count;
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

export function getAuditTrailList(offset: number, limit: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/parking-violation/audit-trail', {
        params: {
          offset,
          limit,
        },
      });
      dispatch(slice.actions.getAuditTrailList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
