import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
//
import BackgroundIllustration from './BackgroundIllustration';

// ----------------------------------------------------------------------

function SparklineIllustration({ ...other }: BoxProps) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box {...other}>
      
        <img
          src="/assets/illustrations/Sparkline.svg"
          alt='violations'
        />
    
    </Box>
  );
}

export default memo(SparklineIllustration);
