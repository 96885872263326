import { Stack } from '@mui/material';
import { formatDate, formatZone } from 'src/utils/format';
import PlateIcon from 'src/assets/illustrations/plate/PlateIcon';
import LocationIcon from 'src/assets/illustrations/plate/LocationIcon';
import DateIcon from 'src/assets/illustrations/plate/DateIcon';
import PlateRow from '../../components/PlateRow';
import { SingleCase } from 'src/@types/violation';
import { findPlateType } from 'src/pages/constants/countries';
import { getCityLabel } from './utils';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ViolationTimeLine from '../../components/ViolationTimeLine';

type Props = {
  violation: SingleCase;
};

export function View({ violation }: Props) {
  const plateType = violation?.Parking?.VehicleLocation?.plateType;
  const plateTypeLabel = findPlateType(
    violation?.Parking?.VehicleLocation?.plateType || ''
  )?.label;
  const createdAt = violation?.createdAt;
  const cityLabel = getCityLabel(violation?.Parking.VehicleLocation.country);
  const licence = violation?.Parking.VehicleLocation.licence;
  const zone = formatZone(
    violation?.Parking?.parkingDescription?.communityNumber,
    violation?.Parking?.parkingDescription?.tariffId
  );
  const overstayTimeLine = [
    violation?.previousScanTimeStamp,
    violation?.Parking?.VehicleLocation?.timeStamp &&
      violation?.Parking?.VehicleLocation?.timeStamp,
  ];
  return (
    <Stack spacing={2}>
      <PlateRow
        isHeader={true}
        title={`${cityLabel}: ${licence}`}
        icon={<PlateIcon sx={{ width: 30 }} />}
      />
      <PlateRow title={`${zone}`} icon={<LocationIcon sx={{ width: 30 }} />} />
      {violation?.previousScanTimeStamp ? (
        <ViolationTimeLine timLine={overstayTimeLine} />
      ) : (
        <PlateRow
          title={`${formatDate(createdAt)}`}
          icon={<DateIcon sx={{ width: 30 }} />}
        />
      )}
      {plateType && (
        <PlateRow
          title={`Plate type: ${plateTypeLabel}`}
          icon={<DirectionsCarIcon sx={{ width: 30 }} />}
        />
      )}
    </Stack>
  );
}
