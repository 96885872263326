import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import FormProvider from 'src/components/hook-form/FormProvider';
import {
  COUNTRIES,
  findPlateType,
  getPlateTypesByCountryId,
  plateTypes,
} from 'src/pages/constants/countries';
import {
  RHFSelect,
  RHFTextField,
  RHFAutocomplete,
} from 'src/components/hook-form';
import { formatZone } from 'src/utils/format';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'src/redux/store';
import { SingleCase } from 'src/@types/violation';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import axios from 'src/utils/axios';
import { getParkingDescriptionList } from 'src/redux/slices/parkingDescriptions';
import { getCityId, getCityLabel } from './utils';
import { setViolation } from 'src/redux/slices/violation';
import * as _ from 'lodash';
import { setIncident } from 'src/redux/slices/incident';
import { LoadingButton } from '@mui/lab';

type Props = {
  onClose: () => void;
  violation: SingleCase;
  onEdit: () => void;
  hideLicencePlate?: boolean;
  hideCountry?: boolean;
  isIncident?: boolean;
};

export function Edit({
  onClose,
  violation,
  onEdit,
  hideLicencePlate,
  hideCountry,
  isIncident,
}: Props) {
  const activePlate = useCallback(
    (countryId?: string) => {
      const countryFound = COUNTRIES.find(
        (country) => country.id === countryId
      );
      if (!countryFound) {
        return [];
      } else {
        const countryPlateTypes = countryFound.types.map((id) =>
          plateTypes.find((plateType) => plateType.id === id)
        );
        const filteredArray = _.filter(countryPlateTypes, { isEnabled: true });
        const intersection = _.intersection(
          _.map(filteredArray, 'id'),
          countryFound.types
        );

        return intersection.map((id) =>
          plateTypes.find((plateType) => plateType.id === id)
        );
      }
    },
    [violation]
  );

  const [plateTypeList, setPlateTypeList] = useState(
    activePlate(violation?.Parking?.VehicleLocation?.country)
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { list: parkingDescriptionList } = useSelector(
    (state: any) => state.parkingDescription
  );
  useEffect(() => {
    dispatch(getParkingDescriptionList());
  }, [dispatch]);

  const { enqueueSnackbar } = useSnackbar();
  const plateTypeLabel = findPlateType(
    violation.Parking.VehicleLocation.plateType
  )?.label;

  const renderCityLabel = () => {
    return getCityLabel(violation?.Parking.VehicleLocation.country);
  };
  const licence =
    isIncident && _.isEmpty(violation?.Parking.VehicleLocation.licence)
      ? ':'
      : violation?.Parking.VehicleLocation.licence;
  const licenceChunks = (licence || '')?.split(':');

  const zone = formatZone(
    violation?.Parking?.parkingDescription?.communityNumber,
    violation?.Parking?.parkingDescription?.tariffId
  );

  const defaultValues = useMemo(() => {
    const defaultLicenceChunks = licenceChunks.reduce(
      (acc, current: string, index: number) => {
        // @ts-ignore
        acc[`licenceChunk${index}`] = current;
        return acc;
      },
      {}
    );

    return {
      ...defaultLicenceChunks,
      country: renderCityLabel(),
      plateType: {
        value: violation.Parking?.VehicleLocation?.plateType,
        label: plateTypeLabel,
      },
      zone: {
        value: violation.Parking.parkingDescriptionId,
        label: zone,
      },
    };
  }, [violation]);

  const parkingDescriptionOptions = useMemo(() => {
    return parkingDescriptionList.map(
      (parkingDescription: { id: string; label: string }) => ({
        value: parkingDescription.id,
        label: parkingDescription.label,
      })
    );
  }, [parkingDescriptionList]);

  const methods = useForm<any>({
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods;

  const watchCountry = watch(['country']);

  const handleCountryChange = useCallback(
    (countryLabel: string | undefined) => {
      const countryId = getCityId(countryLabel);
      setPlateTypeList(getPlateTypesByCountryId(countryId));
    },
    []
  );

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'country') {
        handleCountryChange(value?.country);
      }
    });
    return () => subscription.unsubscribe();
  }, [watchCountry]);

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    axios
      .patch(`/api/parking-violation/${violation?.id}`, {
        licence: licenceChunks
          .map((chunk, index) => data[`licenceChunk${index}`])
          ?.join(':')
          .trim(),
        country: getCityId(data.country),
        zone: data.zone.value,
        plateType: data.plateType.value,
      })
      .then((violationResponse) => {
        enqueueSnackbar(
          `${isIncident ? 'Incident' : 'Violation'}  edited successfully`
        );
        if (isIncident) {
          dispatch(setIncident(violationResponse.data));
        } else {
          dispatch(setViolation(violationResponse.data));
        }

        // trigger a change when we get a new status
        // - the status can come from permissionInfo
        // if (violationResponse?.data?.status !== violation?.status) {
        //   onEdit();
        // }

        onClose();
      })
      .catch((error) => {
        error.message.map((errMessage: any) => {
          enqueueSnackbar(errMessage, { variant: 'error' });
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        {!hideCountry && (
          <>
            <Typography variant="h6">
              Change city: {renderCityLabel()}
            </Typography>
            <RHFSelect
              native
              name="country"
              label="Country"
              placeholder="Country"
            >
              <option value="" />
              {COUNTRIES.map((country) => (
                <option
                  key={country.id}
                  value={country.label}
                  selected={
                    country.id === violation?.Parking.VehicleLocation.country
                  }
                >
                  {country.label}
                </option>
              ))}
            </RHFSelect>
          </>
        )}
        {!hideLicencePlate && (
          <>
            <Typography variant="h6">Change Plate number: {licence}</Typography>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              {licenceChunks?.map((chunk, index) => {
                return (
                  <RHFTextField
                    name={`licenceChunk${index}`}
                    id={`filled-disabled ${index}`}
                    label="Licence plate"
                    defaultValue={chunk}
                    variant="filled"
                    fullWidth={false}
                  />
                );
              })}
            </Stack>
          </>
        )}

        <Typography variant="h6">
          Change plate type: {`${plateTypeLabel}`}
        </Typography>
        <RHFAutocomplete
          name="plateType"
          label="Plate type"
          options={(plateTypeList || [])?.map((type) => {
            return {
              value: type?.id || '',
              label: type?.label || '',
            };
          })}
          //@ts-ignore
          getOptionLabel={(option: { value: string; label: string }) => {
            return (
              option as {
                value: string;
                label: string;
              }
            ).label;
          }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
        />

        <Typography variant="h6">Change Zone: {`${zone}`}</Typography>
        <RHFAutocomplete
          name="zone"
          label="Zone"
          options={parkingDescriptionOptions}
          //@ts-ignore
          getOptionLabel={(option: { value: string; label: string }) => {
            return (
              option as {
                value: string;
                label: string;
              }
            ).label;
          }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
        />
        <Box
          sx={{
            width: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}
        >
          <LoadingButton
            type="submit"
            loading={isLoading}
            loadingIndicator="Loading…"
            sx={{
              mt: 2,
              color: 'grey.900',
              backgroundColor: 'primary.lighter',
              minWidth: 1 / 3,
              maxHeight: '36px',
            }}
          >
            Save Changes
          </LoadingButton>
          <Button
            sx={{
              mt: 2,
              color: 'grey.900',
              backgroundColor: 'grey.200',
              minWidth: 1 / 3,
              maxHeight: '36px',
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
        </Box>
      </Stack>
    </FormProvider>
  );
}
