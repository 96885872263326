import { Box, LinearProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

type CurrentLocationProps = {
  violationDistance?: number;
  isLoading: boolean;
  error?: string;
};
function CurrentLocation({
  violationDistance,
  isLoading,
  error,
}: CurrentLocationProps) {
  const [distance, setDistance] = useState(0);

  useEffect(() => {
    if (violationDistance) {
      setDistance(violationDistance);
    }
  }, [violationDistance]);

  if (isLoading || distance == 0) {
    return (
      <Box sx={{ mb: 5 }}>
        <Typography variant="h3" align="center">
          Waiting for user coordinates...
        </Typography>
        <LinearProgress
          color="inherit"
          sx={{ width: 1, maxWidth: 320, m: '0 auto' }}
        />
      </Box>
    );
  }
  if (error) {
    console.log(error);
    return (
      <Box sx={{ mb: 5 }}>
        <Typography variant="h3" align="center">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Typography variant="h6" align="center">
        Distance to closest vehicle
      </Typography>
      <Typography variant="h1" align="center">
        {distance && distance > 1
          ? `${Math.round(distance)} km`
          : `${distance?.toFixed(3)} m`}
      </Typography>
    </>
  );
}

export default CurrentLocation;
