import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { CustomAvatar } from '../../../components/custom-avatar';
import { USER_ROLES } from 'src/assets/data';
import { useCallback } from 'react';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { user } = useAuthContext();

  const getRoleLabel = useCallback(
    (roleId: string = '') => {
      const roleFound = USER_ROLES.find(
        (currentRole) => currentRole.id.toLowerCase() === roleId.toLowerCase()
      );
      return roleFound?.label;
    },
    [USER_ROLES]
  );

  return (
    <Link
      component={RouterLink}
      to={PATH_DASHBOARD.user.account}
      underline="none"
      color="inherit"
    >
      <StyledRoot>
        <CustomAvatar
          src={user?.photoURL}
          alt={user?.fullName}
          name={user?.fullName}
        />

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.fullName}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {getRoleLabel(user?.role)}
          </Typography>
        </Box>
      </StyledRoot>
    </Link>
  );
}
