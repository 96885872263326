import { VIOLATION_STATUS } from 'src/pages/constants';

type isStatus = {
  isNew: boolean;
  isFollowUp: boolean;
  isToFollowUp: boolean;
  isToPostFine: boolean;
  isIllegalParking: boolean;
};

export default function useViolationActions(status?: string): isStatus {
  switch (status) {
    case VIOLATION_STATUS.NEW:
      return {
        isNew: true,
        isFollowUp: false,
        isToPostFine: false,
        isToFollowUp: false,
        isIllegalParking: false,
      };
    case VIOLATION_STATUS.FOLLOW_UP:
      return {
        isNew: false,
        isFollowUp: true,
        isToPostFine: false,
        isToFollowUp: false,
        isIllegalParking: false,
      };
    case VIOLATION_STATUS.TO_FOLLOW_UP:
      return {
        isNew: false,
        isFollowUp: false,
        isToPostFine: false,
        isToFollowUp: true,
        isIllegalParking: false,
      };
    case VIOLATION_STATUS.TO_POST_FINE:
      return {
        isNew: false,
        isFollowUp: false,
        isToPostFine: true,
        isToFollowUp: false,
        isIllegalParking: false,
      };
    case VIOLATION_STATUS.ILLEGAL_PARKING:
      return {
        isNew: false,
        isFollowUp: false,
        isToPostFine: true,
        isToFollowUp: false,
        isIllegalParking: true,
      };

    default:
      break;
  }
  return {
    isNew: false,
    isFollowUp: false,
    isToPostFine: false,
    isToFollowUp: false,
    isIllegalParking: false,
  };
}
