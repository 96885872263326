import { Box, Typography } from '@mui/material';
type ViolationLicenseCategoryProps = {
  category?: string;
  licence?: string;
  country?: string;
  zone?: string;
  date?: string;
};
export default function ViolationLicenseCategory({
  category,
  licence,
  zone,
  country,
  date,
}: ViolationLicenseCategoryProps) {
  return (
    <Box sx={{ mb: 2 }}>
      <Typography
        sx={{ fontSize: 14, fontWeight: 'bold' }}
        color="text.primary"
        gutterBottom
      >
        Categoory 1: No payment- 10 min grace period
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontSize: 30,
          border: '1px solid black',
          borderRadius: 1,
          padding: 0.5,
        }}
        align="center"
      >
        {licence}
      </Typography>
      <Typography
        color="text.primary"
        variant="body1"
        sx={{ fontWeight: 'bold', mt: 2 }}
        gutterBottom
      >
        SingleScan {country} Zone: {zone}
      </Typography>
      <Typography color="text.primary" variant="body1" gutterBottom>
        Scan time: {date}
      </Typography>
    </Box>
  );
}
