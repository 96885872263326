import { VIOLATION_STATUS } from 'src/pages/constants';

export const getViolationStatusLabel = (status: string = '') => {
  switch (status) {
    case VIOLATION_STATUS.NEW:
      return 'New';
    case VIOLATION_STATUS.FOLLOW_UP:
      return 'Follow up';
    case VIOLATION_STATUS.TO_FOLLOW_UP:
      return 'To Follow up';
    case VIOLATION_STATUS.TO_POST_FINE:
      return 'To post fine';
    case VIOLATION_STATUS.DRIVER_IN_CAR:
      return 'Driver in car';
    case VIOLATION_STATUS.NO_VIOLATION:
      return 'No violation';
    case VIOLATION_STATUS.VEHICLE_ALLOWED:
      return 'Vehicle allowed';
    case VIOLATION_STATUS.CLOSED_DUE_TIME:
      return 'Closed due time';
    case VIOLATION_STATUS.PAID_AFTER_SCAN_TIME:
      return 'Paid after scan time';
    case VIOLATION_STATUS.SKIPPED_DUE_DOUBLE_SCAN:
      return 'Skipped due double scan';
    case VIOLATION_STATUS.SKIPPED_DUE_FINNED_SAME_DAY_SAME_AREA:
      return 'Skipped due fined same day same area';
    case VIOLATION_STATUS.SKIPPED_DUE_PREVIOUSLY_FINNED:
      return 'Skipped due fined previously';

    case VIOLATION_STATUS.SKIPPED_DUE_LOW_CONFIDENCE_LEVEL:
      return 'Skipped due scan car low accuracy';
    case VIOLATION_STATUS.SKIPPED_DUE_SIMILAR_EMIRATES:
      return 'Skipped Due Similar Emirates';
    case VIOLATION_STATUS.SEND_TO_RTA:
      return 'Sent to RTA';
    case VIOLATION_STATUS.ILLEGAL_PARKING:
      return 'Illegal parking';
    case VIOLATION_STATUS.LATE_TRANSACTION:
      return 'Late transaction';
    case VIOLATION_STATUS.UNCLEAR_IMAGES:
      return 'Unclear Images';
    case VIOLATION_STATUS.MOVING_VEHICLE:
      return 'Moving vehicle';
    case VIOLATION_STATUS.NOT_A_VEHICLE:
      return 'Not a vehicle';
    case VIOLATION_STATUS.RESERVED_OR_UNCONTROLLED_PARKING:
      return 'Reserved parking or uncontrolled parking spaces';
    case VIOLATION_STATUS.NO_VIOLATION_AT_SCAN_TIME:
      return 'No violation at scan time';
    case VIOLATION_STATUS.NO_VIOLATION_AT_SCAN_TIME:
      return 'No violation at scan time';
    case VIOLATION_STATUS.SKIPPED_DUE_MISSING_PARKING:
      return 'Skipped due missing parking';
    case VIOLATION_STATUS.JOB_ERROR:
      return 'Job Error';
    case VIOLATION_STATUS.PARKING_FINE_SYSTEM_ERROR:
      return 'Parking fine system error';
    case VIOLATION_STATUS.PLATE_DETAILS_NOT_FOUND:
      return 'Plate details not found';
    case VIOLATION_STATUS.IMAGE_ERROR:
      return 'Image Error';
    case VIOLATION_STATUS.VEHICLE_NOT_PRESENT:
      return 'Vehicle not present';
    default:
      return status?.toLowerCase().split('_').join(' ').toUpperCase();
  }
};
