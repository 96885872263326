import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IncidentState } from 'src/@types/incident';
import axios from '../../utils/axios';

const initialState: IncidentState = {
  isLoading: false,
  error: null,
  count: 0,
  incidentList: { incidentList: [], count: 0 },
  incident: null,
};

const slice = createSlice({
  name: 'incident',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getIncidentList(state, action) {
      state.isLoading = false;
      const incidentList = action.payload.incidentList;
      const count = action.payload.count;
      state.incidentList.incidentList = incidentList;
      state.incidentList.count = count;
    },
    getIncidentCount(state, action) {
      state.isLoading = false;
      const incidentCount = action.payload;
      state.count = incidentCount;
    },
    getIncidentById(state, action) {
      state.isLoading = false;
      state.incident = action.payload;
    },
    setIncident(state, action) {
      state.incident = action.payload;
    },
    resetActiveIncident(state) {
      state.isLoading = false;
      state.incident = initialState.incident;
      state.error = initialState.error;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

export function getIncidentList(
  offset: number,
  limit: number,
  scanner: string
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/incident/list', {
        params: {
          offset,
          limit,
          scanner,
        },
      });
      dispatch(slice.actions.getIncidentList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getIncidentCount() {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/api/incident/count');
      dispatch(slice.actions.getIncidentCount(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getIncidentById(
  id: string | undefined,
  options = { isDebugMode: false }
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/incident/${id}`, {
        params: {
          isDebugMode: options.isDebugMode,
        },
      });
      dispatch(slice.actions.getIncidentById(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setIncident(violation: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setIncident(violation));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
