import { useState } from 'react';
import axios from 'src/utils/axios';
import { Box, Stack } from '@mui/system';
import { Skeleton } from '@mui/material';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import { useSnackbar } from 'notistack';
import MailIcon from 'src/assets/illustrations/violationAction/MailIcon';
import WarningIcon from 'src/assets/illustrations/violationAction/WarningIcon';
import CardComponent from '../components/CardComponent';
import ViolationAction from '../components/ViolationAction';
import { END_POINTS, VIOLATION_STATUS } from 'src/pages/constants';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import useViolationActions from 'src/hooks/useViolationActions';
import CaseLoader from './CaseLoader';
import { getViolationStatusLabel } from 'src/utils/violation';
import NoOffenseAction from './NoOffense';
import DriveInCarIcon from 'src/assets/illustrations/violationAction/DriveInCarIcon';

type ActionProps = {
  violationId: string | undefined;
  violationStatus: string | undefined;
  violationLicence: string | undefined;
  forceShowFollowUpAction?: boolean | undefined;
  isIncident?: boolean;
  isLoading: boolean;
};

type Loader = {
  isLoading: boolean;
  message: string;
};

export default function Actions({
  violationId,
  violationStatus,
  violationLicence,
  forceShowFollowUpAction = true,
  isIncident = false,
  isLoading,
}: ActionProps) {
  const isLicence = violationLicence !== '';

  const { enqueueSnackbar } = useSnackbar();
  const { isToFollowUp, isToPostFine, isFollowUp, isNew, isIllegalParking } =
    useViolationActions(violationStatus);
  const navigate = useNavigate();

  const redirectAfterUpdate = (isFollowUp: boolean) => {
    if (isFollowUp) {
      navigate(PATH_DASHBOARD.followupCases);
    } else {
      navigate(PATH_DASHBOARD.general.newViolations);
    }
  };

  const [loader, setLoader] = useState<Loader>({
    isLoading: false,
    message: '',
  });

  const resetLoader = () => {
    setLoader({
      isLoading: false,
      message: '',
    });
  };

  const canPostFine =
    isToPostFine || isFollowUp || (isNew && isLicence) || isIllegalParking;

  const handlePostFine = () => {
    setLoader({
      isLoading: true,
      message: 'Issuing fine',
    });
    axios
      .post(END_POINTS.parkinViolation.postFine(violationId), {
        isIncident,
      })
      .then((res) => {
        enqueueSnackbar('Fine issued to RTA successfully');
        resetLoader();
        redirectAfterUpdate(isFollowUp);
      })
      .catch((error) => {
        enqueueSnackbar(
          `RTA: ${error?.response?.responseMessageEn}` ||
            'Post fine: something wrong happened ',
          { variant: 'error' }
        );
        resetLoader();
      });
  };

  const handleStatusUpdate = (newStatus: string) => {
    setLoader({
      isLoading: true,
      message: 'Updating violation status',
    });
    axios
      .patch(END_POINTS.parkinViolation.statusUpdate(violationId), {
        status: newStatus,
      })
      .then((res) => {
        if (
          [VIOLATION_STATUS.FOLLOW_UP, VIOLATION_STATUS.SEND_TO_RTA].includes(
            newStatus
          )
        ) {
          enqueueSnackbar(
            `${isIncident ? 'Incident' : 'Case'} successfully  ${
              newStatus === VIOLATION_STATUS.FOLLOW_UP
                ? `sent to inspector`
                : 'issued'
            }`
          );
        } else {
          enqueueSnackbar(
            `${
              isIncident ? 'Incident' : 'Case'
            } Status updated successfully to ${getViolationStatusLabel(
              newStatus
            )}`
          );
        }
        if (isIncident) {
          navigate(PATH_DASHBOARD.general.newIncidents);
        } else {
          redirectAfterUpdate(isFollowUp);
        }
        resetLoader();
      })
      .catch((error) => {
        error.message.map((errMessage: any) => {
          enqueueSnackbar(errMessage, { variant: 'error' });
        });
        resetLoader();
      });
  };

  const renderActions = () => {
    return (
      <>
        {canPostFine && (
          <ViolationAction
            title="Issue direct fine"
            handleAction={handlePostFine}
            icon={<WarningIcon sx={{ width: 30 }} />}
          />
        )}
        {(isToFollowUp || isNew || forceShowFollowUpAction) && !isFollowUp && (
          <ViolationAction
            title="Send fine to field inspector"
            handleAction={() => {
              handleStatusUpdate(VIOLATION_STATUS.FOLLOW_UP);
            }}
            icon={<MailIcon sx={{ width: 30 }} />}
          />
        )}
        {isFollowUp ? (
          <>
            <ViolationAction
              title="Vehicle not present"
              handleAction={() => {
                handleStatusUpdate(VIOLATION_STATUS.VEHICLE_NOT_PRESENT);
              }}
              icon={<NotListedLocationIcon sx={{ width: 30 }} />}
            />
            <ViolationAction
              title="Driver in car"
              handleAction={() => {
                handleStatusUpdate(VIOLATION_STATUS.DRIVER_IN_CAR);
              }}
              icon={<DriveInCarIcon sx={{ width: 30 }} />}
            />
          </>
        ) : (
          <NoOffenseAction handleStatusUpdate={handleStatusUpdate} />
        )}
      </>
    );
  };

  const renderSkeletonLoader = () => {
    return (
      <>
        <Skeleton variant="text" height={38} />
        <Skeleton variant="text" height={38} />
        <Skeleton variant="text" height={38} />
        <Skeleton variant="text" height={38} />
      </>
    );
  };

  return (
    <Box sx={{ gridArea: 'actions' }}>
      <CaseLoader open={loader.isLoading} title={loader.message} />
      <CardComponent title="Actions">
        <Stack
          spacing={2}
          sx={{ height: 1, maxHeight: 300 }}
          justifyContent="space-around"
        >
          {isLoading ? renderSkeletonLoader() : renderActions()}
        </Stack>
      </CardComponent>
    </Box>
  );
}
