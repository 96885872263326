import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
//

// ----------------------------------------------------------------------

function MailIcon({ ...other }: BoxProps) {
  const theme = useTheme();


  return (
    <Box {...other}>
      
        <img
          src="/assets/icons/actions/ic-mail.svg"
          alt='date'
        />
    
    </Box>
  );
}

export default memo(MailIcon);
