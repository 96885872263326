import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import userReducer from './slices/user';
import violationReducer from './slices/violation';
import parkingDescription from './slices/parkingDescriptions';
import followupViolations from './slices/followUpViolations';
import incidentReducer from './slices/incident';
import imagePerformanceReducer from './slices/imagePerformance';
import followupPerformanceReducer from './slices/followUpPerformance';
import zonePerformanceReducer from './slices/zonePerformance';
import scanCarPerfomanceByZoneReducer from './slices/scanCarPerfomanceByZone';
import realTimePerformanceReducer from './slices/realTimePerformance';
import scannedZonesReducer from './slices/scannedZones';
import scanCarCountReducer from './slices/scanCarCount';
import casesCsvReportReducer from './slices/casesReport';
import caseProcessReducer from './slices/caseProcess';
import scanSystemStateReducer from './slices/scanSystemState';
import ScannerReducer from './slices/scanner';
import AuditReducer from './slices/audit';
import ParkingFineSystemReducer from './slices/parkingFineSystem';

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  user: userReducer,
  violation: violationReducer,
  incident: incidentReducer,
  imagePerformance: imagePerformanceReducer,
  followupPerformance: followupPerformanceReducer,
  zonePerformance: zonePerformanceReducer,
  scanCarPerfomanceByZone: scanCarPerfomanceByZoneReducer,
  parkingDescription: parkingDescription,
  followUpViolations: followupViolations,
  realTimePerformance: realTimePerformanceReducer,
  scannedZones: scannedZonesReducer,
  scanCarCount: scanCarCountReducer,
  casesCsvReport: casesCsvReportReducer,
  caseProcess: caseProcessReducer,
  scanSystemState: scanSystemStateReducer,
  scanner: ScannerReducer,
  audit: AuditReducer,
  parkingFineSystem: ParkingFineSystemReducer,
  product: persistReducer(productPersistConfig, productReducer),
});

export default rootReducer;
