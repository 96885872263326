// @mui
import { Stack, TextField, MenuItem, Button } from '@mui/material';
import Iconify from '../../../components/iconify';
import { OptionListsType } from 'src/@types/violation';

type Props = {
  isFiltered: boolean;
  optionList: OptionListsType[];
  onResetFilter: VoidFunction;
  onFilterScanSystem: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function ViolationListToolBar({
  isFiltered,
  onFilterScanSystem,
  optionList,
  onResetFilter,
}: Props) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent={{
        xs: 'center',
        sm: 'flex-end',
      }}
      direction={{
        xs: 'column',
        sm: 'row',
      }}
      sx={{ px: 2.5, py: 3 }}
    >
      {optionList.map((option) => (
        <TextField
          fullWidth
          select
          key={option.label}
          name={option.name}
          label={option.label}
          value={option.value}
          onChange={onFilterScanSystem}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  maxHeight: 260,
                },
              },
            },
          }}
          sx={{
            maxWidth: { sm: 240 },
            textTransform: 'capitalize',
          }}
        >
          {option.options.map((option) => (
            <MenuItem
              key={option}
              value={option}
              sx={{
                mx: 1,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option}
            </MenuItem>
          ))}
        </TextField>
      ))}
      {isFiltered && (
        <Button
          color="error"
          sx={{ flexShrink: 0 }}
          onClick={onResetFilter}
          startIcon={<Iconify icon="eva:trash-2-outline" />}
        >
          Clear
        </Button>
      )}
    </Stack>
  );
}
