import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from 'src/auth/useAuthContext';
import { ROLES } from 'src/assets/data/roles';
import { PATH_PAGE } from 'src/routes/paths';
import { Resource } from 'src/hooks/useResourceAccess';

type ListGuardProps = {
  resource: Resource;
  children: React.ReactNode;
};

export default function UserGuard({ children, resource }: ListGuardProps) {
  const { user } = useAuthContext();

  if (!(user?.role == ROLES.ADMIN)) {
    if (resource == Resource.USER_LIST) {
      if ([ROLES.CASE_REVIEWER].includes(user?.role)) {
        return <Navigate to={PATH_PAGE.page404} />;
      }
    } else if (resource == Resource.USER) {
      return <Navigate to={PATH_PAGE.page404} />;
    }
  }
  return <> {children} </>;
}
