import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import NoOffenseIcon from 'src/assets/illustrations/violationAction/NoOffenseIcon';
import { Typography } from '@mui/material';
import { VIOLATION_STATUS } from 'src/pages/constants';
type Props = {
  handleStatusUpdate: (newStatus: string) => void;
};
export default function NoOffenseAction({ handleStatusUpdate }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        disableRipple
        sx={{
          padding: 0,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          cursor: 'pointer',
          '&:hover': { backgroundColor: 'transparent' },
        }}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <NoOffenseIcon sx={{ width: 30 }} />
        <Typography sx={{ ml: 2, color: 'black' }} variant="body1">
          No Offense
        </Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            handleStatusUpdate(VIOLATION_STATUS.VEHICLE_ALLOWED);
          }}
        >
          Vehicle Allowed
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleStatusUpdate(VIOLATION_STATUS.DRIVER_IN_CAR);
          }}
        >
          Driver in car
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleStatusUpdate(VIOLATION_STATUS.LATE_TRANSACTION);
          }}
        >
          Late transactions
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleStatusUpdate(VIOLATION_STATUS.UNCLEAR_IMAGES);
          }}
        >
          Unclear Images
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleStatusUpdate(VIOLATION_STATUS.MOVING_VEHICLE);
          }}
        >
          Moving vehicle
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleStatusUpdate(
              VIOLATION_STATUS.RESERVED_OR_UNCONTROLLED_PARKING
            );
          }}
        >
          Reserved parking or uncontrolled parking spaces
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleStatusUpdate(VIOLATION_STATUS.NOT_A_VEHICLE);
          }}
        >
          Not a vehicle
        </MenuItem>
      </Menu>
    </div>
  );
}
