import axios from 'src/utils/axios';
import { ImagesCarousel } from 'src/sections/_examples/extra/carousel';
import { SingleCase } from 'src/@types/violation';
import { useDispatch, useSelector } from 'src/redux/store';
import { useEffect, useState, useCallback } from 'react';
import { getFollowUpViolationById } from 'src/redux/slices/followUpViolations';
import { useNavigate, useParams } from 'react-router';
import ViolationLicenseCategory from '../ViolationLicenseCategory';
import { Button, Card } from '@mui/material';
import CurrentLocation from '../CurrentLocation';
import { formatDate, formatZone } from 'src/utils/format';
import FollowUpProcedureAction from './FollowUpProcedureAction';
import getCurrentDistanceToViolation, {
  getCountryLabel,
} from '../../utils/distance';
import Actions from 'src/pages/dashboard/components/Actions';
import { Box } from '@mui/system';
import EmptyContent from 'src/components/empty-content';
import EditViolationData from 'src/pages/dashboard/ViolationDetails/EditViolationData';
import { END_POINTS, VIOLATION_STATUS } from 'src/pages/constants';
import useUserPosition from 'src/hooks/useUserPosition';
import CaseLoader from '../../../components/CaseLoader';
import { useSnackbar } from 'notistack';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { isIncidentVehicleLocation } from 'src/utils/caseType';
import { Loader } from 'src/pages/dashboard/components/Loader';

export default function FollowUpProcedure() {
  const [isGracePeriodLoadeerOpen, setIsGracePeriodLoadeerOpen] =
    useState(false);

  const { isLoading, violation } = useSelector(
    (state) => state.followUpViolations
  );
  const { position, error, isPositionLoading } = useUserPosition();
  const [followUpViolation, setFollowUpViolation] = useState<SingleCase>();
  const [violationDistance, setViolationDistance] = useState(0);

  const dispatch = useDispatch();
  const { caseId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getFollowUpViolationById(caseId));
  }, [dispatch]);

  useEffect(() => {
    if (violation) {
      setFollowUpViolation(violation);
    }
  }, [violation]);
  useEffect(() => {
    if (position) {
      const distance = getCurrentDistanceToViolation(
        position.latitude,
        position.longitude,
        followUpViolation?.Parking?.VehicleLocation?.GPSVehicleCoordinate
          ?.latitude,
        followUpViolation?.Parking?.VehicleLocation?.GPSVehicleCoordinate
          ?.longitude
      );
      setViolationDistance(distance);
    }
  }, [position]);

  const onEdit = useCallback(() => {
    dispatch(getFollowUpViolationById(caseId));
  }, []);

  const recheckAfterGracePeriod = () => {
    setIsGracePeriodLoadeerOpen(true);
    axios
      .post(END_POINTS.parkinViolation.checkCase(caseId), {
        isIncident: isIncidentVehicleLocation(followUpViolation?.Parking),
      })
      .then((res) => {
        if (res.data.status == VIOLATION_STATUS.FOLLOW_UP) {
          enqueueSnackbar("Case haven't been paid yet", { variant: 'warning' });
        } else {
          enqueueSnackbar('Case have already been paid');
          navigate(PATH_DASHBOARD.followupCases);
        }
        setIsGracePeriodLoadeerOpen(false);
      })
      .catch((error) => {
        enqueueSnackbar(
          `RTA: ${error?.response?.responseMessageEn}` ||
            'Post fine: something wrong happened ',
          { variant: 'error' }
        );
        setIsGracePeriodLoadeerOpen(false);
      });
  };
  const handleNavigateToLocation = () => {
    window.open(
      `https://www.google.com/maps/dir/${position.latitude},${position.longitude}/${followUpViolation?.Parking?.VehicleLocation?.GPSVehicleCoordinate?.latitude},${followUpViolation?.Parking?.VehicleLocation?.GPSVehicleCoordinate?.longitude}`,
      '_blank',
      'noopener,noreferrer'
    );
  };

  if (isLoading) {
    return (
      <Box sx={{ m: 1, height: 100 }}>
        <Loader />
      </Box>
    );
  }

  if (!isLoading && !violation) {
    return <EmptyContent title="Case not found" />;
  }
  return (
    <>
      <CurrentLocation
        isLoading={isPositionLoading}
        error={error}
        violationDistance={violationDistance}
      />
      <Card sx={{ padding: 2, m: 2 }}>
        <ViolationLicenseCategory
          licence={followUpViolation?.Parking?.VehicleLocation?.licence}
          country={getCountryLabel(
            violation?.Parking?.VehicleLocation?.country
          )}
          date={formatDate(
            followUpViolation?.Parking?.VehicleLocation?.timeStamp
          )}
          zone={formatZone(
            violation?.Parking?.parkingDescription?.communityNumber,
            violation?.Parking?.parkingDescription?.tariffId
          )}
        />
        <Button
          variant="contained"
          fullWidth
          sx={{ my: 1 }}
          onClick={handleNavigateToLocation}
        >
          Navigate to location
        </Button>

        <ImagesCarousel
          data={
            followUpViolation?.Parking?.VehicleLocation?.ImagesDetail?.images
          }
          isLoading={isLoading}
        />
        <CaseLoader
          open={isGracePeriodLoadeerOpen}
          title={'Checking case information'}
        />
        <FollowUpProcedureAction
          actionHandler={recheckAfterGracePeriod}
          actionLabel="Recheck after 10 min grace period"
        />

        <Box
          sx={{
            mb: 2,
            mt: 2,
          }}
        >
          <EditViolationData
            violation={violation}
            isActiveViolation={
              violation?.status[0]?.status === VIOLATION_STATUS.FOLLOW_UP
            }
            onEdit={onEdit}
            hideLicencePlate
            hideCountry
            isLoading={isLoading}
          />
        </Box>

        <Actions
          violationId={caseId}
          violationLicence={followUpViolation?.Parking.VehicleLocation.licence}
          violationStatus={followUpViolation?.status[0].status}
          isIncident={isIncidentVehicleLocation(followUpViolation?.Parking)}
          isLoading={isLoading}
        />
      </Card>
    </>
  );
}
