import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material';
interface GracePeriodLoaderProps extends DialogProps {}

export default function CaseLoader({ open, title }: GracePeriodLoaderProps) {
  return (
    <Dialog
      PaperProps={{
        sx: {
          width: '70%',
          height: {
            xs: 150,
            md: 200,
          },
          justifyContent: 'center',
        },
      }}
      open={open}
    >
      <DialogTitle textAlign="center" variant="h5">
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="body2"
          textAlign="center"
          sx={{
            fontSize: '1rem',
            mb: {
              xs: 3,
              md: 6,
            },
          }}
        >
          Thank you for waiting
        </Typography>
        <LinearProgress color="primary" sx={{ width: 1 }} />
      </DialogContent>
    </Dialog>
  );
}
