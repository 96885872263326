import { useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Box,
  Card,
} from '@mui/material';
import Scrollbar from '../../../../../../components/scrollbar';
import {
  useTable,
  getComparator,
  TableHeadCustom,
  TablePaginationCustom,
} from '../../../../../../components/table';
import { useNavigate } from 'react-router';
import { ICase } from 'src/@types/violation';
import { useSelector } from 'src/redux/store';
import { formatDate, formatZone } from 'src/utils/format';
import { getViolationStatusLabel } from 'src/utils/violation';
import EmptyContent from 'src/components/empty-content/EmptyContent';
import { Loader } from 'src/pages/dashboard/components/Loader';
import { isEmpty } from 'lodash';
import ViolationListToolBar from 'src/pages/dashboard/ViolationList/ViolationListToolBar';

const TABLE_HEAD = [
  { id: 'scanSystem', label: 'Scan Car', align: 'center' },
  { id: 'date', label: 'Date & Time', align: 'center' },
  { id: 'license', label: 'License', align: 'center' },
  { id: 'zone', label: 'Zone', align: 'center' },
  { id: 'status', label: 'Status', align: 'center' },
];

type Props = {
  handlePaginationChange: (
    offset: number,
    limit: number,
    scanner: string
  ) => void;
};
export function IncidentTable({ handlePaginationChange }: Props) {
  const [searchParams, setSearchParams] = useSearchParams({ car: 'All' });
  const { scannerDescriptionList } = useSelector((state) => state.scanner);

  const { isLoading, error, incidentList } = useSelector(
    (state) => state.incident
  );

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    onSort,
    onChangeDense,
    onChangeRowsPerPage,
    setPage,
  } = useTable({
    defaultOrderBy: 'createdAt',
    defaultRowsPerPage: 10,
  });

  const scanCar = searchParams.get('car') || 'All';
  const isFiltered = ![scanCar].every((element) => element == 'All');
  const scanSystemOptionList = ['All'].concat(scannerDescriptionList!);

  const navigate = useNavigate();

  const dataFiltered = applyFilter({
    inputData: incidentList?.incidentList,
    comparator: getComparator(order, orderBy),
  });

  useEffect(() => {
    handlePaginationChange(0, rowsPerPage, scanCar);
  }, [rowsPerPage, searchParams]);

  const handleRowClick = (id: string) => {
    navigate(`/dashboard/new-incidents/${id}`);
  };

  const onPaginate = useCallback(
    (event: unknown, newPage: number) => {
      handlePaginationChange(rowsPerPage * newPage, rowsPerPage, scanCar);
      setPage(newPage);
    },
    [rowsPerPage]
  );

  const handleScanSystemChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.name === 'scanCar') {
      setSearchParams({ car: event.target.value });
    }
  };

  const handleResetFilter = () => {
    setSearchParams({ car: 'All' });
  };

  if (isLoading) {
    return (
      <Box sx={{ m: 1, height: 100 }}>
        <Loader />
      </Box>
    );
  }

  return (
    <div>
      <Card>
        {
          // TODO: add a hook for this toolbar
        }
        <ViolationListToolBar
          optionList={[
            {
              options: scanSystemOptionList,
              name: 'scanCar',
              value: scanCar,
              label: 'Scan car',
            },
          ]}
          onFilterScanSystem={handleScanSystemChange}
          isFiltered={isFiltered}
          onResetFilter={handleResetFilter}
        />
        {!isLoading && isEmpty(dataFiltered) ? (
          <EmptyContent title="There are no new cases at this moment" />
        ) : (
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={incidentList?.count}
                  onSort={onSort}
                />
                <TableBody>
                  {dataFiltered?.map((row) => {
                    return (
                      <TableRow
                        onClick={() => {
                          handleRowClick(row?.id);
                        }}
                        hover
                        key={row?.id}
                        sx={{
                          m: 3,
                          cursor: 'pointer',
                        }}
                      >
                        <TableCell align="center">
                          {
                            row?.Parking?.VehicleLocation?.scanner
                              ?.systemDescription
                          }
                        </TableCell>
                        <TableCell align="center">
                          {formatDate(row?.createdAt)}
                        </TableCell>
                        <TableCell align="center">
                          {row.Parking?.VehicleLocation?.licence}
                        </TableCell>
                        <TableCell align="center">
                          {formatZone(
                            row?.Parking.parkingDescription?.communityNumber,
                            row?.Parking.parkingDescription?.tariffId
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {getViolationStatusLabel(row?.status[0]?.status)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
        )}

        <TablePaginationCustom
          count={incidentList?.count || 0}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={onPaginate}
          onRowsPerPageChange={onChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </Card>
    </div>
  );
}

function applyFilter({
  inputData,
  comparator,
}: {
  inputData: ICase[] | undefined;
  comparator: (a: any, b: any) => number;
}) {
  const stabilizedThis = inputData?.map((el, index) => [el, index] as const);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  inputData = stabilizedThis?.map((el) => el[0]);
  return inputData;
}
