import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
function ImageReviewPerformanceIllustration({ ...other }: BoxProps) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box {...other}>
      
        <img
          src="/assets/illustrations/image-review-performance.svg"
          alt='violations'
        />
    
    </Box>
  );
}

export default memo(ImageReviewPerformanceIllustration);
