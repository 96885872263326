import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { ICaseState, OptionsType } from 'src/@types/violation';

const initialState: ICaseState = {
  isLoading: false,
  error: null,
  newViolationList: { violationList: [], count: 0 },
  parkingWithoutViolationList: { archivedCasesList: [], count: 0 },
  violation: null,
};

const slice = createSlice({
  name: 'violation',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getNewViolationList(state, action) {
      state.isLoading = false;
      const violationList = action.payload.violations;
      const count = action.payload.count;
      state.newViolationList.violationList = violationList;
      state.newViolationList.count = count;
    },
    parkingWithoutViolationList(state, action) {
      state.isLoading = false;
      const list = action.payload.archivedCasesList;
      const count = action.payload.count;
      state.parkingWithoutViolationList.archivedCasesList = list;
      state.parkingWithoutViolationList.count = count;
    },
    getViolationById(state, action) {
      state.isLoading = false;
      state.violation = action.payload;
    },
    setViolation(state, action) {
      state.violation = action.payload;
    },
    resetActiveViolation(state) {
      state.isLoading = false;
      state.violation = initialState.violation;
      state.error = initialState.error;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

export function getNewViolationList(
  offset: number,
  limit: number,
  scanner: string,
  options: OptionsType
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/parking-violation/new-list', {
        params: {
          offset,
          limit,
          scanner,
          ...options,
        },
      });
      dispatch(slice.actions.getNewViolationList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getParkingWithoutViolationList(
  offset: number,
  limit: number,
  status: string
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/parking-violation/not-new-list', {
        params: {
          offset,
          limit,
          status,
        },
      });
      dispatch(slice.actions.parkingWithoutViolationList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getViolationById(
  id: string | undefined,
  options = { isDebugMode: false }
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/parking-violation/${id}`, {
        params: {
          isDebugMode: options.isDebugMode,
        },
      });
      dispatch(slice.actions.getViolationById(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setViolation(violation: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setViolation(violation));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function resetActiveViolation() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.resetActiveViolation());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
