import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { ReportQueryType } from 'src/@types/reports';
import { ParkingFineState } from 'src/@types/parking-fine-system';

const initialState: ParkingFineState = {
  isLoading: false,
  error: null,
  list: [],
};

const slice = createSlice({
  name: 'parkingFineSystem',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getParkingFineSystemCount(state, action) {
      state.list = action.payload;
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

export function getParkingFineSystemCount({
  endDate,
  startDate,
}: Partial<ReportQueryType>) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/analytics/pfs-report', {
        params: {
          endDate,
          startDate,
        },
      });

      dispatch(slice.actions.getParkingFineSystemCount(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
