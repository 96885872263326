import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { ScannedZonesState } from 'src/@types/performance';

const initialState: ScannedZonesState = {
  isLoading: false,
  error: null,
  list: [],
};

const slice = createSlice({
  name: 'scannedZones',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getScannedZonesList(state, action) {
      state.isLoading = false;
      state.list = action.payload;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

export function getScannedZonesList() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/analytics/zone-scanned`);
      dispatch(slice.actions.getScannedZonesList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
