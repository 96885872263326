import { Suspense, lazy, ElementType } from 'react';
import LoadingScreen from '../components/loading-screen';

// @ts-ignore
const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// AUTH
export const LoginPage = Loadable(
  lazy(() => import('../pages/auth/LoginPage'))
);
export const RegisterPage = Loadable(
  lazy(() => import('../pages/auth/RegisterPage'))
);
export const VerifyCodePage = Loadable(
  lazy(() => import('../pages/auth/VerifyCodePage'))
);
export const NewPasswordPage = Loadable(
  lazy(() => import('../pages/auth/NewPasswordPage'))
);
export const ResetPasswordPage = Loadable(
  lazy(() => import('../pages/auth/ResetPasswordPage'))
);

// DASHBOARD: GENERAL
export const GeneralAppPage = Loadable(
  lazy(() => import('../pages/dashboard/GeneralAppPage'))
);
export const ViolationsListPage = Loadable(
  lazy(() => import('../pages/dashboard/ViolationsListPage'))
);
export const ViolationDetails = Loadable(
  lazy(() => import('../pages/dashboard/ViolationDetails/ViolationDetails'))
);
export const ParkingWithoutViolationPage = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/ParkingWithoutViolation/ParkingWithoutViolationPage'
      )
  )
);
export const AudiTrailListPage = Loadable(
  lazy(() => import('../pages/dashboard/AudiTrailList'))
);
export const IncidentsPage = Loadable(
  lazy(() => import('../pages/dashboard/IncidentsPage'))
);
export const GeneralScanCarPage = Loadable(
  lazy(
    () => import('../pages/dashboard/ScanCarAnalytics/ScanCarsAnalyticsPage')
  )
);
export const GeneralOverViewPage = Loadable(
  lazy(() => import('../pages/dashboard/OverView/OverViewAnalyticsPage'))
);
export const GeneralVehicleMonitorPage = Loadable(
  lazy(() => import('../pages/dashboard/VehicleMonitor/VehicleMonitorPage'))
);
export const GeneralBankingPage = Loadable(
  lazy(() => import('../pages/dashboard/GeneralBankingPage'))
);
export const GeneralBookingPage = Loadable(
  lazy(() => import('../pages/dashboard/GeneralBookingPage'))
);
export const GeneralFilePage = Loadable(
  lazy(() => import('../pages/dashboard/GeneralFilePage'))
);
export const FollowupCasePage = Loadable(
  lazy(() => import('../pages/dashboard/Followup/FollowupCasePage'))
);
export const FollowUpProcedure = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/Followup/components/FolloWUpProcedure/FollowUpProcedure'
      )
  )
);

export const CasesProcessPage = Loadable(
  lazy(() => import('../pages/dashboard/CaseProcess/CasesProcessContainer'))
);
export const ParkingFineSystemPage = Loadable(
  lazy(
    () => import('../pages/dashboard/ParkingFineSystem/ParkingFineSystemPage')
  )
);
// DASHBOARD: INVOICE
// export const InvoiceListPage = Loadable(lazy(() => import('../pages/dashboard/InvoiceListPage')));
// export const InvoiceDetailsPage = Loadable(
//   lazy(() => import('../pages/dashboard/InvoiceDetailsPage'))
// );
// export const InvoiceCreatePage = Loadable(
//   lazy(() => import('../pages/dashboard/InvoiceCreatePage'))
// );
// export const InvoiceEditPage = Loadable(lazy(() => import('../pages/dashboard/InvoiceEditPage')));

// DASHBOARD: USER
export const UserProfilePage = Loadable(
  lazy(() => import('../pages/dashboard/UserProfilePage'))
);
export const UserCardsPage = Loadable(
  lazy(() => import('../pages/dashboard/UserCardsPage'))
);
export const UserListPage = Loadable(
  lazy(() => import('../pages/dashboard/UserListPage'))
);
export const UserAccountPage = Loadable(
  lazy(() => import('../pages/dashboard/UserAccountPage'))
);
export const UserCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/UserCreatePage'))
);
export const UserEditPage = Loadable(
  lazy(() => import('../pages/dashboard/UserEditPage'))
);

// DASHBOARD: FILE MANAGER
export const FileManagerPage = Loadable(
  lazy(() => import('../pages/dashboard/FileManagerPage'))
);

// DASHBOARD: APP

// TEST RENDER PAGE BY ROLE
export const PermissionDeniedPage = Loadable(
  lazy(() => import('../pages/dashboard/PermissionDeniedPage'))
);

// BLANK PAGE
export const BlankPage = Loadable(
  lazy(() => import('../pages/dashboard/BlankPage'))
);

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
// export const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
// export const FaqsPage = Loadable(lazy(() => import('../pages/FaqsPage')));
// export const AboutPage = Loadable(lazy(() => import('../pages/AboutPage')));
// export const Contact = Loadable(lazy(() => import('../pages/ContactPage')));
// export const PricingPage = Loadable(lazy(() => import('../pages/PricingPage')));
// export const PaymentPage = Loadable(lazy(() => import('../pages/PaymentPage')));
export const ComingSoonPage = Loadable(
  lazy(() => import('../pages/ComingSoonPage'))
);
export const MaintenancePage = Loadable(
  lazy(() => import('../pages/MaintenancePage'))
);

// DEMO COMPONENTS
// ----------------------------------------------------------------------

export const ComponentsOverviewPage = Loadable(
  lazy(() => import('../pages/components/ComponentsOverviewPage'))
);

// FOUNDATION
// export const FoundationColorsPage = Loadable(
//   lazy(() => import('../pages/components/foundation/FoundationColorsPage'))
// );
// export const FoundationTypographyPage = Loadable(
//   lazy(() => import('../pages/components/foundation/FoundationTypographyPage'))
// );
// export const FoundationShadowsPage = Loadable(
//   lazy(() => import('../pages/components/foundation/FoundationShadowsPage'))
// );
// export const FoundationGridPage = Loadable(
//   lazy(() => import('../pages/components/foundation/FoundationGridPage'))
// );
// export const FoundationIconsPage = Loadable(
//   lazy(() => import('../pages/components/foundation/FoundationIconsPage'))
// );

// MUI COMPONENTS
// export const MUIAccordionPage = Loadable(
//   lazy(() => import('../pages/components/mui/MUIAccordionPage'))
// );
// export const MUIAlertPage = Loadable(lazy(() => import('../pages/components/mui/MUIAlertPage')));
// export const MUIAutocompletePage = Loadable(
//   lazy(() => import('../pages/components/mui/MUIAutocompletePage'))
// );
// export const MUIAvatarPage = Loadable(lazy(() => import('../pages/components/mui/MUIAvatarPage')));
// export const MUIBadgePage = Loadable(lazy(() => import('../pages/components/mui/MUIBadgePage')));
// export const MUIBreadcrumbsPage = Loadable(
//   lazy(() => import('../pages/components/mui/MUIBreadcrumbsPage'))
// );
// export const MUIButtonsPage = Loadable(
//   lazy(() => import('../pages/components/mui/MUIButtonsPage'))
// );
// export const MUICheckboxPage = Loadable(
//   lazy(() => import('../pages/components/mui/MUICheckboxPage'))
// );
// export const MUIChipPage = Loadable(lazy(() => import('../pages/components/mui/MUIChipPage')));
// export const MUIDataGridPage = Loadable(
//   lazy(() => import('../pages/components/mui/MUIDataGridPage'))
// );
// export const MUIDialogPage = Loadable(lazy(() => import('../pages/components/mui/MUIDialogPage')));
// export const MUIListPage = Loadable(lazy(() => import('../pages/components/mui/MUIListPage')));
// export const MUIMenuPage = Loadable(lazy(() => import('../pages/components/mui/MUIMenuPage')));
// export const MUIPaginationPage = Loadable(
//   lazy(() => import('../pages/components/mui/MUIPaginationPage'))
// );
// export const MUIPickersPage = Loadable(
//   lazy(() => import('../pages/components/mui/MUIPickersPage'))
// );
// export const MUIPopoverPage = Loadable(
//   lazy(() => import('../pages/components/mui/MUIPopoverPage'))
// );
// export const MUIProgressPage = Loadable(
//   lazy(() => import('../pages/components/mui/MUIProgressPage'))
// );
// export const MUIRadioButtonsPage = Loadable(
//   lazy(() => import('../pages/components/mui/MUIRadioButtonsPage'))
// );
// export const MUIRatingPage = Loadable(lazy(() => import('../pages/components/mui/MUIRatingPage')));
// export const MUISliderPage = Loadable(lazy(() => import('../pages/components/mui/MUISliderPage')));
// export const MUIStepperPage = Loadable(
//   lazy(() => import('../pages/components/mui/MUIStepperPage'))
// );
// export const MUISwitchPage = Loadable(lazy(() => import('../pages/components/mui/MUISwitchPage')));
// export const MUITablePage = Loadable(lazy(() => import('../pages/components/mui/MUITablePage')));
// export const MUITabsPage = Loadable(lazy(() => import('../pages/components/mui/MUITabsPage')));
// export const MUITextFieldPage = Loadable(
//   lazy(() => import('../pages/components/mui/MUITextFieldPage'))
// );
// export const MUITimelinePage = Loadable(
//   lazy(() => import('../pages/components/mui/MUITimelinePage'))
// );
// export const MUITooltipPage = Loadable(
//   lazy(() => import('../pages/components/mui/MUITooltipPage'))
// );
// export const MUITransferListPage = Loadable(
//   lazy(() => import('../pages/components/mui/MUITransferListPage'))
// );
// export const MUITreesViewPage = Loadable(
//   lazy(() => import('../pages/components/mui/MUITreesViewPage'))
// );
