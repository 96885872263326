import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { ScanCarCountState } from 'src/@types/scan-car';
import axios from '../../utils/axios';

const initialState: ScanCarCountState = {
  isLoading: false,
  error: null,
  list: [],
  pastWeekDates: [],
  currentMonthCount:{count: 0}
};

const slice = createSlice({
  name: 'scanCarCount',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getScanCarCountData(state, action) {
      state.isLoading = false;
      state.list = action.payload.carCount;
      state.pastWeekDates = action.payload.pastWeekDates.dates;
      state.currentMonthCount = action.payload.currentMonthCount;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

export function getScanCarCountData() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/analytics/scan-car-count`);
      dispatch(slice.actions.getScanCarCountData(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
