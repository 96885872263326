import { useCallback } from 'react';
import { COUNTRIES } from 'src/pages/constants/countries';

export default function getCurrentDistanceToViolation(
  userLatitude: number,
  userLongtitude: number,
  violationLatitude?: number,
  violationLongtitude?: number
) {
  // The math module contains a function
  // named toRadians which converts from
  // degrees to radians.
  userLongtitude = (userLongtitude * Math.PI) / 180;
  violationLongtitude = (violationLongtitude! * Math.PI) / 180;
  userLatitude = (userLatitude * Math.PI) / 180;
  violationLatitude = (violationLatitude! * Math.PI) / 180;

  // Haversine formula
  let dlon = violationLongtitude - userLongtitude;
  let dlat = violationLatitude - userLatitude;
  let a =
    Math.pow(Math.sin(dlat / 2), 2) +
    Math.cos(userLatitude) *
      Math.cos(violationLatitude) *
      Math.pow(Math.sin(dlon / 2), 2);

  let c = 2 * Math.asin(Math.sqrt(a));

  // Radius of earth in kilometers. Use 3956
  // for miles
  let r = 6371;

  // calculate the result
  return c * r;
}

export const getCountryLabel = (cityId: string | undefined = '') => {
  const country = COUNTRIES.find(
    (emirate) => emirate.id.toLowerCase() === cityId.toLowerCase()
  );
  return country?.label;
};
