import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import useActiveLink from 'src/hooks/useActiveLink';

type NavProps = {
  path: string;
  text: string;
  extraData?: string;
  isActive?: boolean;
};
export default function NavItem({
  path,
  text,
  extraData,
  isActive = false,
}: NavProps) {
  const { active: activeLink } = useActiveLink(path);
  const navigate = useNavigate();

  const active = isActive || activeLink;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: active ? '#F9F4F4' : '',
        p: 1,
        my: 1,
        width: 222,
        height: 56,
        borderRadius: 1.25,
        cursor: 'pointer',
      }}
      onClick={() => navigate(path)}
    >
      {extraData ? (
        <Stack direction="row" justifyContent="center" alignItems="center"  >
          <Typography
            sx={{
              mr: '3px',
              textAlign: 'center',
              bgcolor: '#FF5630',
              borderRadius: '20px',
              width: '45px',
              color: 'white',
              padding: '5px',
            }}
          >
            {extraData}
          </Typography>
          <Typography
            variant={active ? 'h6' : 'body1'}
            sx={{ width: 1, textAlign: 'center' }}
          >
            {text}
          </Typography>
        </Stack>
      ) : (
        <Typography
          variant={active ? 'h6' : 'body1'}
          sx={{ width: 1, textAlign: 'center' }}
        >
          {text}
        </Typography>
      )}
    </Box>
  );
}
