export const SCAN_CARS_MOCK_DATA = [
  {
    id: '01',
    date: '2023-02-09T19:35:21.359Z',
    driver: 'Tarik Al Sadr',
    zonesScanned: ['333A', '123C', '303B', '112A'],
    totalTimeDrinving: '12',
    scanTimeline: [
      { time: '07:55', action: 'Started the vehicle' },
      { time: '08:15', action: 'First scan ID 88 in Zone 2A' },
      { time: '08:55', action: 'Stopped Vehicle' },
      { time: '09:03  -  Started vehicle 09', action: `Started vehicle` },
      { time: '09:55', action: 'Enter Zone 3B' },
      { time: '10:26', action: 'Enter Zone 3BC' },
      { time: '11:43', action: 'Enter Zone 4A' },
    ],
    carPaused: '1',
    scanned: 32440,
  },
  {
    id: '02',
    date: '2023-02-09T19:35:21.359Z',
    driver: 'Khalid Hasan',
    zonesScanned: ['303B', '112A'],
    totalTimeDrinving: '8',
    scanTimeline: [
      { time: '07:55', action: 'Started the vehicle' },
      { time: '08:15', action: 'First scan ID 88 in Zone 2A' },
      { time: '08:55', action: 'Stopped Vehicle' },
      { time: '09:03  -  Started vehicle 09', action: `Started vehicle` },
      { time: '09:55', action: 'Enter Zone 3B' },
      { time: '10:26', action: 'Enter Zone 3BC' },
      { time: '11:43', action: 'Enter Zone 4A' },
    ],
    carPaused: '2',
    scanned: 2440,
  },
  {
    id: '09',
    date: '2023-02-09T19:35:21.359Z',
    driver: 'Jamal Ben Ali',
    zonesScanned: ['234A', '330C', '131A'],
    totalTimeDrinving: '8',
    scanTimeline: [
      { time: '07:55', action: 'Started the vehicle' },
      { time: '08:15', action: 'First scan ID 88 in Zone 2A' },
      { time: '08:55', action: 'Stopped Vehicle' },
      { time: '09:03  -  Started vehicle 09', action: `Started vehicle` },
      { time: '09:55', action: 'Enter Zone 3B' },
      { time: '10:26', action: 'Enter Zone 3BC' },
      { time: '11:43', action: 'Enter Zone 4A' },
    ],
    carPaused: '1',
    scanned: 5444,
  },
  {
    id: '03',
    date: '2023-02-09T19:35:21.359Z',
    driver: 'Yusuf Lamzira',
    zonesScanned: ['333A', '240B', '233B'],
    totalTimeDrinving: '12',
    scanTimeline: [
      { time: '07:55', action: 'Started the vehicle' },
      { time: '08:15', action: 'First scan ID 88 in Zone 2A' },
      { time: '08:55', action: 'Stopped Vehicle' },
      { time: '09:03  -  Started vehicle 09', action: `Started vehicle` },
      { time: '09:55', action: 'Enter Zone 3B' },
      { time: '10:26', action: 'Enter Zone 3BC' },
      { time: '11:43', action: 'Enter Zone 4A' },
    ],
    carPaused: '1',
    scanned: 42044,
  },
  {
    id: '08',
    date: '2023-02-09T19:35:21.359Z',
    driver: 'Patul Jalal',
    zonesScanned: ['234A', '330C', '131A'],
    totalTimeDrinving: '12',
    scanTimeline: [
      { time: '07:55', action: 'Started the vehicle' },
      { time: '08:15', action: 'First scan ID 88 in Zone 2A' },
      { time: '08:55', action: 'Stopped Vehicle' },
      { time: '09:03  -  Started vehicle 09', action: `Started vehicle` },
      { time: '09:55', action: 'Enter Zone 3B' },
      { time: '10:26', action: 'Enter Zone 3BC' },
      { time: '11:43', action: 'Enter Zone 4A' },
    ],
    carPaused: '2',
    scanned: 23423,
  },
];

export const IMAGE_REVIEW_PERFORMANCE_MOCK = [
  {
    userName: 'K. Ben Rafik',
    fineIssued: 100,
    sentToFollowUp: 55,
    driverInCar: 10,
    noOffense: 25,
    vehicleAllowed: 10,
  },
  {
    userName: 'S. Halloul',
    fineIssued: 40,
    sentToFollowUp: 66,
    driverInCar: 10,
    noOffense: 40,
    vehicleAllowed: 5,
  },
  {
    userName: 'D. Ali',
    fineIssued: 95,
    sentToFollowUp: 55,
    driverInCar: 10,
    noOffense: 20,
    vehicleAllowed: 10,
  },
  {
    userName: 'M. Mohammad',
    fineIssued: 100,
    sentToFollowUp: 32,
    driverInCar: 10,
    noOffense: 25,
    vehicleAllowed: 10,
  },
];
export const FOLLOW_UP_PERFORMANCE_MOCK = [
  {
    userName: 'K. Ben Rafik',
    fineIssued: 100,
    vehicleNotPresent: 55,
    warning: 10,
  },
  {
    userName: 'S. Halloul',
    fineIssued: 40,
    vehicleNotPresent: 66,
    warning: 10,
  },
  {
    userName: 'D. Ali',
    fineIssued: 95,
    vehicleNotPresent: 55,
    warning: 10,
  },
  {
    userName: 'M. Mohammad',
    fineIssued: 100,
    vehicleNotPresent: 32,
    warning: 10,
  },
];
export const ZONE_PERFORMANCE_MOCK = [
  {
    zone: '214A',
    followedUp: '60/100',
    expired: '40/100',
    percentage: 50,
  },

  {
    zone: '393D',
    followedUp: '40/80',
    expired: '40/80',
    percentage: 50,
  },
  {
    zone: '365D',
    followedUp: '20/100',
    expired: '80/100',
    percentage: 80,
  },
  {
    zone: '111C',
    followedUp: '10/66',
    expired: '30/66',
    percentage: 40,
  },
];

export const SCAN_CAR_PERFORMANCE_BY_ZONE_MOCK = [
  {
    zone: '214A',
    vehicleID: 1,
    parkingSpacesPerZone: 3500,
    scansMade: 1866,
    permitted: 1567,
    cat1: 299,
    cat2: 87,
  },

  {
    zone: '393D',
    vehicleID: 2,
    parkingSpacesPerZone: 1550,
    scansMade: 1296,
    permitted: 1132,
    cat1: 134,
    cat2: 99,
  },

  {
    zone: '365D',
    vehicleID: 3,
    parkingSpacesPerZone: 1896,
    scansMade: 25,
    permitted: 12,
    cat1: 13,
    cat2: 5,
  },

  {
    zone: '111C',
    vehicleID: 4,
    parkingSpacesPerZone: 2259,
    scansMade: 398,
    permitted: 177,
    cat1: 211,
    cat2: 10,
  },
];
export const DATE_RANGE = ['Today', 'Last Week', 'Last Month'];

export const CSV_IMAGE_REVIEW_REPORT_DATA = [
  ['K. Ben Rafik', 100, 55, 10, 25, 10],
  ['S. Halloul', 40, 66, 10, 40, 5],
  ['D. Ali', 95, 55, 10, 20, 10],
  ['M. Mohammad', 100, 32, 10, 25, 10],
];
export const CSV_FOLLOW_UP_REPORT_DATA = [
  ['K. Ben Rafik', 100, 55, 10],
  ['S. Halloul', 40, 66, 10],
  ['D. Ali', 95, 55, 10],
  ['M. Mohammad', 100, 32, 10],
];
export const CSV_ZONE_REPORT_DATA = [
  ['214A', '60/100', '40/100', 50],
  ['393D', '40/80', '40/80', 50],
  ['365D', '20/100', '80/100', 80],
  ['111C', '13/66', '30/66', 40],
];

export const CSV_SCAN_CAR_PERFORMANCE_BY_ZONE_REPORT_DATA = [
  ['214A', 1, 3500, 1866, 1567, 299, 87],
  ['393D', 2, 1550, 1296, 1132, 134, 99],
  ['365D', 3, 1896, 25, 12, 13, 5],
  ['111C', 4, 2259, 398, 177, 211, 10],
];
