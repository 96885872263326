import { Dispatch, createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
type ScannerState = {
  isLoading: boolean;
  error: Error | string | null;
  scannerDescriptionList: string[] | undefined;
};
const initialState: ScannerState = {
  isLoading: false,
  error: null,
  scannerDescriptionList: [],
};

const slice = createSlice({
  name: 'scanner',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getScannerDescriptionList(state, action) {
      state.scannerDescriptionList = action.payload;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

export function getScannerDescriptionList() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/scanner/scanner-list');
      dispatch(slice.actions.getScannerDescriptionList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
