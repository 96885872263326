import { INCIDENT_CAMERAS } from 'src/pages/constants';

export const getCaseType = (cameras?: string) => {
  if (cameras !== undefined) {
    if (
      [
        INCIDENT_CAMERAS.CAM_MANUAL_LEFT,
        INCIDENT_CAMERAS.CAM_MANUAL_RIGHT,
      ].includes(cameras)
    ) {
      return 'Incident';
    } else {
      return 'Case';
    }
  }
};

export function isIncidentVehicleLocation(data: any): boolean {
  return [
    INCIDENT_CAMERAS.CAM_MANUAL_LEFT,
    INCIDENT_CAMERAS.CAM_MANUAL_RIGHT,
  ].includes(data?.VehicleLocation?.cameras);
}
