export const formatDate = (date: any) => {
  const parsedDate = new Date(Date.parse(date));
  const fullDate = parsedDate.toLocaleDateString();
  const time = parsedDate.toLocaleTimeString();
  return `${fullDate} ${time}`;
};

export const formatZone = (community_no: any, tariffId: any) => {
  if (community_no && tariffId) {
    const regexCondition = /Tarrif|Tarriff|<Null>|Tariff| /g;
    const abc = tariffId?.replace(regexCondition, '');
    return community_no + abc;
  }
  return 'N/A';
};

export function toPascalCase(input: string): string {
  const words = input.toLowerCase().split('_');

  const pascalCase = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');

  return pascalCase;
}

export function toKebabCase(input: string) {
  return input
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/([a-z])([A-Z])/g, '$1-$2') // Insert hyphens between lowercase and uppercase letters
    .toLowerCase(); // Convert the whole string to lowercase
}
