import { memo } from 'react';
import { Box, BoxProps } from '@mui/material';

function PlateIcon({ ...other }: BoxProps) {
  return (
    <Box {...other}>
        <img
          src="/assets/icons/carInfo/ic-plate.svg"
          alt='plate'
        />
    </Box>
  );
}

export default memo(PlateIcon);
