import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { ScanCarPerformanceByZoneState } from 'src/@types/performance';
import {  SCAN_CAR_PERFORMANCE_BY_ZONE_MOCK } from 'src/pages/dashboard/OverView/components/mock';

const initialState: ScanCarPerformanceByZoneState = {
  isLoading: false,
  error: null,
  list: [],
};

const slice = createSlice({
  name: 'scanCarPerformance',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getScanCarPerformanceList(state, action) {
      state.isLoading = false;
      const scanCarPerformanceList = action.payload;

      state.list = scanCarPerformanceList;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

export function getScanCarPerformanceList() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/repoerting/followup-performance');

      dispatch(
        slice.actions.getScanCarPerformanceList(SCAN_CAR_PERFORMANCE_BY_ZONE_MOCK)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
