import { useState, useEffect } from 'react';

export default function useUserPosition() {
  const [position, setPosition] = useState<any>();
  const [error, setError] = useState<string>();
  const [isPositionLoading, setIsPositionLoading] = useState(true);

  useEffect(() => {
    const geolocation = navigator.geolocation;

    if (!geolocation) {
      setError('Geolocation is not supported');
      setIsPositionLoading(false);
      return;
    }

    const onSuccess = (pos: any) => {
      const { latitude, longitude } = pos.coords;
      setPosition({ latitude, longitude });
      setIsPositionLoading(false);
    };

    const onError = (err: any) => {
      setError(err.message);
      setIsPositionLoading(false);
    };

    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    const watchId = geolocation.watchPosition(onSuccess, onError, options);

    return () => geolocation.clearWatch(watchId);
  }, []);

  return { position, error, isPositionLoading };
}
