import { Typography } from "@mui/material";
import { Box } from "@mui/system";

type Props = {
    title: React.ReactNode;
    icon: React.ReactNode;
    isHeader?: Boolean;
};
export default function PlateRow({icon,  title, isHeader}: Props){


    return (
      <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
      >
        {icon}
       <Typography sx={{ml: 2}} variant={isHeader ? "h4" : "body2"}>{title}</Typography>
      </Box>
    );
}