import { Navigate } from 'react-router-dom';
import { PATH_PAGE } from 'src/routes/paths';
import { Resource, useResourceAccess } from 'src/hooks/useResourceAccess';

type ListGuardProps = {
  children: React.ReactNode;
};

export default function AuditTrailGuard({ children }: ListGuardProps) {
  const { canRead: canReadAuditTrailList } = useResourceAccess(
    Resource.VIOLATION_AUDIT_TRAIL
  );
  if (!canReadAuditTrailList) {
    return <Navigate to={PATH_PAGE.page404} />;
  }
  return <> {children} </>;
}
