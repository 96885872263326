import { memo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

function DateIcon({ ...other }: BoxProps) {
  const theme = useTheme();

  return (
    <Box {...other}>
      <img src="/assets/icons/carInfo/ic-date.svg" alt="date" />
    </Box>
  );
}

export default memo(DateIcon);
