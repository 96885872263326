import { Box, Button, Skeleton, Tooltip, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CardComponent from '../../components/CardComponent';
import { useState } from 'react';
import { SingleCase } from 'src/@types/violation';
import { View } from './view';
import { Edit } from './edit';
import { getErrorDescription } from './errorMapper';

type EditViolationDataProps = {
  violation: SingleCase | null;
  isActiveViolation: boolean;
  onEdit: () => void;
  hideLicencePlate?: boolean;
  hideCountry?: boolean;
  isIncident?: boolean;
  isLoading?: boolean;
};

export default function EditViolationData({
  violation,
  isActiveViolation,
  onEdit,
  hideLicencePlate,
  hideCountry,
  isIncident,
  isLoading,
}: EditViolationDataProps) {
  const [showEdit, setShowEdit] = useState(false);
  const handleEditClick = () => {
    setShowEdit((prev) => !prev);
  };

  const renderTitle = () => {
    if (!isActiveViolation) {
      return 'Details';
    } else {
      return !violation?.PermissionInfo[0]?.hasError ? (
        `Category ID : ${violation?.PermissionInfo?.[0].categoryId}`
      ) : (
        <Tooltip
          title={getErrorDescription(
            violation?.PermissionInfo?.[0].faultstring
          )}
        >
          <Typography variant="h6" display="flex" alignItems="center">
            Category ID : unknown &nbsp; <HelpOutlineIcon />
          </Typography>
        </Tooltip>
      );
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ gridArea: 'plateDescription' }}>
        <CardComponent
          maxHeight={400}
          title={<Skeleton variant="text" height={40} width={140} />}
          action={
            <Skeleton
              variant="text"
              width={64}
              height={36}
              sx={{
                mt: 2,
                maxWidth: '64px',
                maxHeight: '36px',
              }}
            />
          }
        >
          <Skeleton variant="text" height={38} />
          <Skeleton variant="text" height={38} />
          <Skeleton variant="text" height={38} />
          <Skeleton variant="text" height={38} />
        </CardComponent>
      </Box>
    );
  }

  if (!violation) {
    return null;
  }

  return (
    <Box sx={{ gridArea: 'plateDescription' }}>
      <CardComponent
        maxHeight={400}
        title={renderTitle()}
        action={
          isActiveViolation && !showEdit ? (
            <Button
              sx={{
                mt: 2,
                color: 'grey.900',
                backgroundColor: 'primary.lighter',
                maxWidth: '64px',
                maxHeight: '36px',
              }}
              onClick={handleEditClick}
            >
              Edit
            </Button>
          ) : (
            ''
          )
        }
      >
        {!showEdit ? (
          <View violation={violation} />
        ) : (
          <Edit
            violation={violation}
            onClose={() => setShowEdit(false)}
            onEdit={onEdit}
            isIncident={isIncident}
            hideLicencePlate={hideLicencePlate}
            hideCountry={hideCountry}
          />
        )}
      </CardComponent>
    </Box>
  );
}
