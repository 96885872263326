export enum ROLES {
  ADMIN = 'ADMIN',
  CASE_REVIEWER = 'CASE_REVIEWER',
  SHIFT_MANAGER = 'SHIFT_MANAGER',
  FOLLOW_UP_AGENT = 'FOLLOW_UP_AGENT',
}

export const USER_ROLES = [
  { id: ROLES.ADMIN, label: 'Admin' },
  { id: ROLES.CASE_REVIEWER, label: 'Case reviewer' },
  { id: ROLES.SHIFT_MANAGER, label: 'Shift manager' },
  { id: ROLES.FOLLOW_UP_AGENT, label: 'Follow up agent' },
];
