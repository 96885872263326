import { LinearProgress } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';

export const Loader = () => (
  <Stack
    alignItems="center"
    justifyContent="center"
    display="flex"
    sx={{
      height: '100%',
    }}
  >
    <LinearProgress color="inherit" sx={{ width: '50%' }} />
  </Stack>
);
