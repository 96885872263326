import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { ImagePerformanceState } from 'src/@types/performance';
import { ReportQueryType } from 'src/@types/reports';

const initialState: ImagePerformanceState = {
  isLoading: false,
  error: null,
  list: [],
  count: 0,
};

const slice = createSlice({
  name: 'imagePerormance',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getImagePerformanceList(state, action) {
      state.isLoading = false;

      state.list = action.payload.imageReportList;
      state.count = action.payload.count[0]?.count;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

export function getImagePerformanceList({
  endDate,
  startDate,
  limit,
  offset,
}: ReportQueryType) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/analytics/image-performance', {
        params: {
          offset,
          limit,
          endDate,
          startDate,
        },
      });

      dispatch(slice.actions.getImagePerformanceList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
