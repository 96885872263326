import { Navigate, useRoutes } from 'react-router-dom';
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
import {
  PATH_AFTER_LOGIN,
  PATH_AFTER_LOGIN_FOLLOW_UP_AGENT,
} from '../config-global';
import {
  LoginPage,
  GeneralAppPage,
  UserListPage,
  UserEditPage,
  UserCreatePage,
  UserAccountPage,
  PermissionDeniedPage,
  Page500,
  Page403,
  Page404,
  ViolationsListPage,
  AudiTrailListPage,
  ParkingWithoutViolationPage,
  ViolationDetails,
  FollowupCasePage,
  GeneralScanCarPage,
  GeneralVehicleMonitorPage,
  GeneralOverViewPage,
  CasesProcessPage,
  ParkingFineSystemPage,
} from './elements';
import IncidentsPage from 'src/pages/dashboard/IncidentsPage';
import { Resource } from 'src/hooks/useResourceAccess';
import UserGuard from 'src/guards/UserGuard';
import AuditTrailGuard from 'src/guards/AuditTrailGuard';
import { useAuthContext } from 'src/auth/useAuthContext';
import { ROLES } from 'src/assets/data';
import RoleBasedGuard from 'src/auth/RoleBasedGuard';
import FollowUpProcedure from 'src/pages/dashboard/Followup/components/FolloWUpProcedure/FollowUpProcedure';

export default function Router() {
  const { user } = useAuthContext();
  const isFollowUpAgent = user?.role == ROLES.FOLLOW_UP_AGENT;

  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/auth/login" />,
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: isFollowUpAgent ? (
            <Navigate to={PATH_AFTER_LOGIN_FOLLOW_UP_AGENT} replace />
          ) : (
            <Navigate to={PATH_AFTER_LOGIN} replace />
          ),
          index: true,
        },
        {
          path: 'app',
          element: isFollowUpAgent ? (
            <Navigate to={PATH_AFTER_LOGIN_FOLLOW_UP_AGENT} replace />
          ) : (
            <RoleBasedGuard
              hasContent={true}
              roles={[ROLES.CASE_REVIEWER, ROLES.ADMIN, ROLES.SHIFT_MANAGER]}
            >
              <GeneralAppPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'follow-up',
          children: [
            {
              element: (
                <RoleBasedGuard
                  hasContent={true}
                  roles={[
                    ROLES.FOLLOW_UP_AGENT,
                    ROLES.ADMIN,
                    ROLES.SHIFT_MANAGER,
                  ]}
                >
                  <FollowupCasePage />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: ':caseId',
              element: (
                <RoleBasedGuard
                  hasContent={true}
                  roles={[
                    ROLES.FOLLOW_UP_AGENT,
                    ROLES.ADMIN,
                    ROLES.SHIFT_MANAGER,
                  ]}
                >
                  <FollowUpProcedure />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'new-violations',
          children: [
            {
              element: (
                <RoleBasedGuard
                  hasContent={true}
                  roles={[
                    ROLES.ADMIN,
                    ROLES.CASE_REVIEWER,
                    ROLES.SHIFT_MANAGER,
                  ]}
                >
                  <ViolationsListPage />
                </RoleBasedGuard>
              ),
              index: true,
            },
            { path: ':id/debug', element: <ViolationDetails /> },
            {
              path: ':id',
              element: (
                <RoleBasedGuard
                  hasContent={true}
                  roles={[
                    ROLES.ADMIN,
                    ROLES.CASE_REVIEWER,
                    ROLES.SHIFT_MANAGER,
                  ]}
                >
                  <ViolationDetails />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'parking-without-violations',
          children: [
            { element: <ParkingWithoutViolationPage />, index: true },
            {
              path: 'case/:id',
              element: (
                <RoleBasedGuard
                  hasContent={true}
                  roles={[
                    ROLES.ADMIN,
                    ROLES.CASE_REVIEWER,
                    ROLES.SHIFT_MANAGER,
                  ]}
                >
                  <ViolationDetails />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'incident/:id',
              element: (
                <RoleBasedGuard
                  hasContent={true}
                  roles={[
                    ROLES.ADMIN,
                    ROLES.CASE_REVIEWER,
                    ROLES.SHIFT_MANAGER,
                  ]}
                >
                  <ViolationDetails isIncident />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'audit-trail',
          element: (
            <AuditTrailGuard>
              <AudiTrailListPage />
            </AuditTrailGuard>
          ),
          index: true,
        },
        {
          path: 'new-incidents',
          children: [
            { element: <IncidentsPage />, index: true },
            { path: ':id', element: <ViolationDetails isIncident={true} /> },
          ],
        },
        {
          path: 'cases-process',

          children: [
            {
              element: (
                <RoleBasedGuard hasContent={true} roles={[ROLES.ADMIN]}>
                  <CasesProcessPage />
                </RoleBasedGuard>
              ),
              index: true,
            },
          ],
        },
        {
          path: 'parking-fine-system',
          element: (
            <RoleBasedGuard
              hasContent={true}
              roles={[ROLES.ADMIN, ROLES.SHIFT_MANAGER]}
            >
              <ParkingFineSystemPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'analytics',
          element: (
            <RoleBasedGuard
              hasContent={true}
              roles={[ROLES.ADMIN, ROLES.SHIFT_MANAGER]}
            >
              <GeneralScanCarPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'overview',
          element: (
            <RoleBasedGuard
              hasContent={true}
              roles={[ROLES.ADMIN, ROLES.SHIFT_MANAGER]}
            >
              <GeneralOverViewPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'vehicle-monitor',
          element: (
            <RoleBasedGuard
              hasContent={true}
              roles={[ROLES.ADMIN, ROLES.SHIFT_MANAGER]}
            >
              <GeneralVehicleMonitorPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'user',
          // @ts-ignore
          children: [
            {
              element: <Navigate to="/dashboard/user/account" replace />,
              index: true,
            },
            {
              path: 'list',
              element: (
                <UserGuard resource={Resource.USER_LIST}>
                  <UserListPage />
                </UserGuard>
              ),
            },

            {
              path: 'new',
              element: (
                <UserGuard resource={Resource.USER}>
                  <UserCreatePage />
                </UserGuard>
              ),
            },

            {
              path: ':username/edit',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[ROLES.ADMIN, ROLES.SHIFT_MANAGER]}
                >
                  <UserEditPage />
                </RoleBasedGuard>
              ),
            },
            { path: 'account', element: <UserAccountPage /> },
          ].filter(Boolean),
        },

        { path: 'permission-denied', element: <PermissionDeniedPage /> },
      ],
    },
    {
      element: <Navigate to="/dashboard" replace />,
    },
    {
      element: <CompactLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
