import { Stack, Typography } from '@mui/material';
import LoginLayout from '../../layouts/login';
import AuthLoginForm from './AuthLoginForm';

export default function Login() {
  return (
    <LoginLayout title="Leader in urban intelligence">
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h3">Welcome to RTA parking management</Typography>
        <Typography variant="h4">Sign in</Typography>
      </Stack>
      <AuthLoginForm />
    </LoginLayout>
  );
}
