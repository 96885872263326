import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { ScanSystemState } from 'src/@types/scan-system';

const initialState: ScanSystemState = {
  isLoading: false,
  error: null,
  list: [],
  details: [],
};

const slice = createSlice({
  name: 'scanSystemState',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getScanSystemStateList(state, action) {
      const scanSystemList = action.payload;
      state.list = scanSystemList;
      state.isLoading = false;
    },
    getScanSystemStateCoordinatesList(state, action) {
      const scanSystemStateList = action.payload;
      state.details = scanSystemStateList;
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

export function getScanSystemStateList() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/scan-system-list');

      dispatch(slice.actions.getScanSystemStateList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getScanSystemStateCoordinatesList(
  timePeriod: number,
  date?: string
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/scan-system-list/coordinates', {
        params: {
          timePeriod,
          date,
        },
      });

      dispatch(slice.actions.getScanSystemStateCoordinatesList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
