import { useState, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Stack, useMediaQuery, Slider } from '@mui/material';
import Image from '../../../../components/image';
import Carousel, { CarouselArrowIndex } from '../../../../components/carousel';
import { Image as Images } from 'src/@types/violation';
import ImageMagnifier from './ImageMagnifier';

const THUMB_SIZE = 64;

type Props = {
  data: Images[] | undefined;
  isLoading?: boolean;
};

type StyledThumbnailsContainerProps = {
  length: number | undefined;
};

const StyledThumbnailsContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'length',
})<StyledThumbnailsContainerProps>(({ length, theme }) => ({
  margin: theme.spacing(0, 'auto'),
  position: 'relative',

  '& .slick-slide': {
    opacity: 0.48,
    '&.slick-current': {
      opacity: 1,
    },
    '& > div': {
      padding: theme.spacing(0, 0.75),
    },
  },
}));

const DEFAULT_BRIGHTNESS = 100;

export default function ImagesCarousel({ data, isLoading }: Props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [brightness, setBrightness] = useState(DEFAULT_BRIGHTNESS);

  const isXs = useMediaQuery('(max-width:720px)');

  const [nav1, setNav1] = useState<Carousel | undefined>(undefined);

  const [nav2, setNav2] = useState<Carousel | undefined>(undefined);

  const carousel1 = useRef<Carousel | null>(null);

  const carousel2 = useRef<Carousel | null>(null);

  const carouselSettings1 = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    adaptiveHeight: true,
    beforeChange: (current: number, next: number) => setCurrentIndex(next),
  };

  const carouselSettings2 = {
    dots: false,
    arrows: false,
    centerMode: true,
    swipeToSlide: false,
    focusOnSelect: true,
    variableWidth: true,
    centerPadding: '0px',
    slidesToShow: data?.length,
  };

  useEffect(() => {
    if (carousel1.current) {
      setNav1(carousel1.current);
    }
    if (carousel2.current) {
      setNav2(carousel2.current);
    }
  }, []);

  useEffect(() => {
    setBrightness(DEFAULT_BRIGHTNESS);
  }, [currentIndex]);

  const handleBrightnessChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    setBrightness(newValue as number);
  };

  const handlePrev = () => {
    carousel2.current?.slickPrev();
  };

  const handleNext = () => {
    carousel2.current?.slickNext();
  };

  const renderLargeImg = () => (
    <Box
      sx={{
        mb: 3,
        zIndex: 0,
        borderRadius: 2,
        maxHeight: isXs ? 300 : 610,
        minHeight: isXs ? 300 : 515,
        objectFit: 'contain',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Carousel {...carouselSettings1} asNavFor={nav2} ref={carousel1}>
        {data?.map((item) => (
          <Box key={item.id}>
            <ImageMagnifier
              imgSrc={item.url}
              brightness={brightness}
              magnifierRadius={80}
            />
          </Box>
        ))}
      </Carousel>

      <CarouselArrowIndex
        index={currentIndex}
        total={data?.length}
        onNext={handleNext}
        onPrevious={handlePrev}
      />
    </Box>
  );

  const renderThumbnails = () => (
    <StyledThumbnailsContainer length={data?.length}>
      <Carousel {...carouselSettings2} asNavFor={nav1} ref={carousel2}>
        {data?.map((item, index) => (
          <Image
            key={item.id}
            disabledEffect
            alt={`violation image ${index}`}
            src={item.url}
            sx={{
              width: THUMB_SIZE,
              height: THUMB_SIZE,
              borderRadius: 1.5,
              cursor: 'pointer',
              ...(currentIndex === index && {
                border: (theme) => `solid 2px ${theme.palette.primary.main}`,
              }),
            }}
          />
        ))}
      </Carousel>
    </StyledThumbnailsContainer>
  );

  return (
    <Box
      sx={{
        '& .slick-slide': {
          float: (theme) => (theme.direction === 'rtl' ? 'right' : 'left'),
        },
      }}
    >
      {renderLargeImg()}

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ m: 2 }}
      >
        <Stack width={300}>
          <Slider
            aria-label="brightness"
            defaultValue={100}
            step={10}
            marks
            min={50}
            max={200}
            value={brightness}
            onChange={handleBrightnessChange}
            size="medium"
          />
        </Stack>
      </Box>

      {renderThumbnails()}
    </Box>
  );
}
