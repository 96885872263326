// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  followupCases: path(ROOTS_DASHBOARD, '/follow-up'),
  followupCase: (caseId: string) => {
    path(ROOTS_DASHBOARD, `/follow-up/${caseId}`);
  },
  fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  auditTrail: { auditTrail: path(ROOTS_DASHBOARD, `/audit-trail`) },
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    newViolations: path(ROOTS_DASHBOARD, '/new-violations'),
    violation: (violationId: string) =>
      path(ROOTS_DASHBOARD, `/new-violations/${violationId}`),
    parkingWithoutViolationList: path(
      ROOTS_DASHBOARD,
      '/parking-without-violations'
    ),
    inactiveViolation: (violationId: string) =>
      path(ROOTS_DASHBOARD, `/parking-without-violations/case/${violationId}`),
    inactiveIncident: (violationId: string) =>
      path(
        ROOTS_DASHBOARD,
        `/parking-without-violations/incident/${violationId}`
      ),
    newIncidents: path(ROOTS_DASHBOARD, '/new-incidents'),
    casesProcess: path(ROOTS_DASHBOARD, '/cases-process'),
    incident: (incidentId: string) =>
      path(ROOTS_DASHBOARD, `/new-incidents/${incidentId}`),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    overView: path(ROOTS_DASHBOARD, '/overview'),
    vehicleMonitor: path(ROOTS_DASHBOARD, '/vehicle-monitor'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    file: path(ROOTS_DASHBOARD, '/file'),
    parkingFineSystem: path(ROOTS_DASHBOARD, '/parking-fine-system'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (username: string) => path(ROOTS_DASHBOARD, `/user/${username}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },

  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(
      ROOTS_DASHBOARD,
      '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'
    ),
    demoView: path(
      ROOTS_DASHBOARD,
      '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'
    ),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE =
  'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE =
  'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION =
  'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
