import { Helmet } from 'react-helmet-async';
import { Card, Container, Grid } from '@mui/material';
import { useSettingsContext } from '../../components/settings';

import DashboardTab from './components/DashboardTab';
import { useDispatch } from 'src/redux/store';

import { getIncidentList } from 'src/redux/slices/incident';

import { IncidentTable } from 'src/sections/@dashboard/general/app/components/sorting-selecting/IncidentTable';
import { useEffect } from 'react';
import { getScannerDescriptionList } from 'src/redux/slices/scanner';

export default function IncidentsPage() {
  const { themeStretch } = useSettingsContext();
  const dispatch = useDispatch();

  const handlePaginationChange = (
    offset: number,
    limit: number,
    scanner: string
  ) => {
    getIncidentListData(offset, limit, scanner);
  };

  const getIncidentListData = (
    offset: number,
    limit: number,
    scanner: string
  ) => {
    dispatch(getIncidentList(offset, limit, scanner));
  };

  useEffect(() => {
    dispatch(getScannerDescriptionList());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Incidents List | CityVU</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid item xs={12} lg={12}>
          <DashboardTab />
          <Card>
            <IncidentTable handlePaginationChange={handlePaginationChange} />
          </Card>
        </Grid>
      </Container>
    </>
  );
}
