import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { ICaseProcessState } from 'src/@types/caseProcess';

const initialState: ICaseProcessState = {
  isLoading: false,
  error: null,
  list: [],
  count: 0,
};

const slice = createSlice({
  name: 'caseProcess',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getCaseProcessList(state, action) {
      state.isLoading = false;
      const list = action.payload.data;
      const count = action.payload.count;
      state.list = list;
      state.count = count;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

export function getCaseProcessList(offset: number, limit: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/case-process/list', {
        params: {
          offset,
          limit,
        },
      });
      dispatch(slice.actions.getCaseProcessList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
