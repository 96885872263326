import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { FollowUpPerformanceState } from 'src/@types/performance';
import { ReportQueryType } from 'src/@types/reports';

const initialState: FollowUpPerformanceState = {
  isLoading: false,
  error: null,
  list: [],
  count: 0,
};

const slice = createSlice({
  name: 'followUpPerformance',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getFollowUpPerformanceList(state, action) {
      const followUpPerformanceList = action.payload.followUpReportList;

      state.list = followUpPerformanceList;
      state.count = action.payload.count[0]?.count;
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

export function getFollowUpPerformanceList({
  endDate,
  startDate,
  offset,
  limit,
}: ReportQueryType) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/analytics/followup-performance', {
        params: {
          offset,
          limit,
          startDate,
          endDate,
        },
      });

      dispatch(slice.actions.getFollowUpPerformanceList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
