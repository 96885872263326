import {
  COUNTRIES,
  findPlateType,
  getPlateTypesByCountryId,
} from 'src/pages/constants/countries';

export const getCityId = (cityLabel: string = '') => {
  const country = COUNTRIES.find((emirate) => emirate.label === cityLabel);
  return country?.id;
};

export const getCityLabel = (cityId: string | undefined = '') => {
  const country = COUNTRIES.find(
    (emirate) => emirate.id.toLowerCase() === cityId.toLowerCase()
  );
  return country?.label;
};
