import { Card, CardContent, CardHeader, CardProps } from '@mui/material';

interface Props extends CardProps {
  title: any;
  action?: React.ReactNode;
  children: React.ReactNode;
  maxHeight?: number | string;
};

export default function CardComponent({
  children,
  title,
  action,
  maxHeight,
  ...other
}: Props) {
  return (
    <Card {...other}>
      <CardHeader
        sx={{
          backgroundColor: 'error.lighter',
          height: '64px',
          pt: 0,
        }}
        title={title}
        action={action}
      />
      <CardContent >{children}</CardContent>
    </Card>
  );
}
