import {
  format,
  getTime,
  formatDistanceToNow,
  startOfWeek,
  endOfWeek,
  subWeeks,
  subDays,
} from 'date-fns';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null | undefined;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || ' dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}
export function getSevenDaysAgoDate() {
  const currentDate = new Date();
  const today = format(currentDate, 'dd MMM');
  const sevenDaysAgo = format(subDays(currentDate, 6), 'dd MMM'); // Subtract 6 days to get to September 9th

  return { today, sevenDaysAgo };
}
