import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { CasesReportQuery, CasesReportState } from 'src/@types/cases-report';

const initialState: CasesReportState = {
  isLoading: false,
  error: null,
  list: [],
  count: 0,
};

const slice = createSlice({
  name: 'casesReport',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    casesCsvReportList(state, action) {
      state.isLoading = false;
      state.list = action.payload.cases;
      state.count = action.payload.pagination.count;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

export function getCasesCsvReportData({
  offset = 0,
  limit = 10,
  startDate,
  endDate,
}: CasesReportQuery) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/analytics/cases-report`, {
        params: {
          offset,
          limit,
          startDate,
          endDate,
        },
      });
      dispatch(slice.actions.casesCsvReportList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
