import {
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';

type Props = {
  title: React.ReactNode;
  handleAction: () => void;
  icon: React.ReactNode;
};
export default function ViolationAction({ handleAction, icon, title }: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={handleAction}
    >
      {icon}
      <Typography sx={{ ml: 2 }} variant="body1">
        {title}
      </Typography>
    </Box>
  );
}
