import omit from 'lodash/omit';
import keyBy from 'lodash/keyBy';
import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
// @types
import { IUserState } from '../../@types/user';

// ----------------------------------------------------------------------

const initialState: IUserState = {
  isLoading: false,
  error: null,
  list: [],
  profile: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getUserList(state, action) {
      state.isLoading = false;
      const userList = action.payload;

      state.list = userList;
    },
    getUserByUserName(state, action) {
      state.isLoading = false;
      const profile = action.payload;
      state.profile = profile;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getUserList() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/users');
      dispatch(slice.actions.getUserList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getUserByUserName(userName?: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/users/${userName}`);
      dispatch(slice.actions.getUserByUserName(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
