import { ROLES } from './../assets/data/roles';
import { USER_ROLES } from 'src/assets/data';
import { useLocation, matchPath } from 'react-router-dom';
import { useAuthContext } from 'src/auth/useAuthContext';

export enum Resource {
  USER_LIST,
  USER,
  VIOLATION_AUDIT_TRAIL
}

export interface Access {
  canRead: boolean;
  canWrite: boolean;
}

export function useResourceAccess(resource: Resource): Access {
  const { user } = useAuthContext();

  switch (resource) {
    case Resource.USER:
      return {
        canRead: [
          ROLES.ADMIN,
          ROLES.SHIFT_MANAGER,
          ROLES.CASE_REVIEWER,
        ].includes(user?.role),
        canWrite: [ROLES.ADMIN].includes(user?.role),
      };
    case Resource.USER_LIST:
      return {
        canRead: [ROLES.ADMIN, ROLES.SHIFT_MANAGER].includes(
          user?.role
        ),
        canWrite: [ROLES.ADMIN].includes(user?.role),
      };
      
    case Resource.VIOLATION_AUDIT_TRAIL:
      return {
        canRead: [ROLES.ADMIN, ROLES.SHIFT_MANAGER].includes(
          user?.role
        ),
        canWrite: false,
      };
      
    default:
      break;
  }

  return {
    canRead: false,
    canWrite: false,
  };
}
