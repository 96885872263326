import { Button } from '@mui/material';

type FolloWUpProcedureActionProps = {
  actionHandler: () => void;
  actionLabel: string;
};
export default function FollowUpProcedureAction({
  actionHandler,
  actionLabel,
}: FolloWUpProcedureActionProps) {
  return (
    <Button variant="outlined" onClick={actionHandler} fullWidth sx={{ mb: 1 }}>
      {actionLabel}
    </Button>
  );
}
