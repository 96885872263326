import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, BoxProps, Typography } from '@mui/material';
import Image from '../image/Image';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const MiniLogo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 35,
          height: 35,
          left: 0,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <Image src="/assets/images/logo/rta-mini-logo.png" />
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link
        component={RouterLink}
        to="/dashboard/"
        sx={{ display: 'contents' }}
      >
        {logo}
      </Link>
    );
  }
);

export default MiniLogo;
