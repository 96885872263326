import { memo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

function FollowUpIllustration({ ...other }: BoxProps) {
  const theme = useTheme();
  return (
    <Box {...other}>
      <img
        src="/assets/illustrations/followup-performance.svg"
        alt="violations"
      />
    </Box>
  );
}

export default memo(FollowUpIllustration);
