import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { IParkingDescriptionList } from 'src/@types/violation';
import { formatZone } from 'src/utils/format';


const initialState: IParkingDescriptionList = {
  isLoading: false,
  error: null,
  list: [],
};

const slice = createSlice({
  name: 'parkingDescription',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getParkingDescriptionList(state, action) {
      state.isLoading = false;

      const parkingDescription = action.payload;
      state.list = parkingDescription;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getParkingDescriptionList() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        '/api/parking-violation/parking-description'
      );

      const parkingDescObjects = response.data
        .map((parkingDescription: any) => {
          return {
            label: formatZone(
              parkingDescription.communityNumber,
              parkingDescription.tariffId
            ),
            id: parkingDescription.id,
          };
        });
      dispatch(slice.actions.getParkingDescriptionList(parkingDescObjects));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
