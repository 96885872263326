import { PATH_DASHBOARD } from '../routes/paths';
import SvgColor from '../components/svg-color';
import { Resource, useResourceAccess } from './useResourceAccess';
import { useAuthContext } from 'src/auth/useAuthContext';
import { ROLES } from 'src/assets/data';
import SpeedIcon from '@mui/icons-material/Speed';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  blog: icon('ic_blog'),
  whistle: icon('ic_whistle'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  monitor: icon('ic_monitor'),
  overview: icon('ic_overview'),
  incident: icon('ic_incident'),
  speed: <SpeedIcon />,
  process: <CachedRoundedIcon color="disabled" />,
};

function useNavConfig() {
  const { canRead: canReadUserList } = useResourceAccess(Resource.USER_LIST);
  const { canRead: canReadAuditTrailList } = useResourceAccess(
    Resource.VIOLATION_AUDIT_TRAIL
  );
  const { user } = useAuthContext();
  if (user?.role == ROLES.FOLLOW_UP_AGENT) {
    return [
      {
        subheader: 'Violations',
        items: [
          {
            title: 'Violations',
            path: PATH_DASHBOARD.followupCases,
            icon: ICONS.dashboard,
          },
        ],
      },
      {
        subheader: 'Account',
        items: [
          {
            title: 'Profile',
            path: PATH_DASHBOARD.user.account,
            icon: ICONS.dashboard,
          },
        ],
      },
    ];
  }
  const navigations = [
    {
      subheader: 'Quick Menu',
      items: [
        {
          title: 'dashboard',
          path: PATH_DASHBOARD.general.app,
          icon: ICONS.dashboard,
        },
        {
          title: 'Review violations',
          path: PATH_DASHBOARD.general.newViolations,
          icon: ICONS.whistle,
        },
        {
          title: 'Review incidents',
          path: PATH_DASHBOARD.general.newIncidents,
          icon: ICONS.incident,
        },
      ],
    },
    {
      subheader: 'management',
      items: [
        {
          title: 'user',
          path: PATH_DASHBOARD.user.root,
          icon: ICONS.user,
          children: [
            { title: 'account', path: PATH_DASHBOARD.user.account },
            canReadUserList
              ? { title: 'list', path: PATH_DASHBOARD.user.list }
              : null,
          ].filter(Boolean),
        },
      ],
    },
  ];
  const AdminSiftManagersSubHeaders = [
    {
      subheader: 'Cases process',
      items: [
        {
          title: 'List',
          path: PATH_DASHBOARD.general.casesProcess,
          icon: ICONS.process,
        },
      ],
    },
    {
      subheader: 'Cases archive',
      items: [
        {
          title: 'all cases',
          path: PATH_DASHBOARD.general.parkingWithoutViolationList,
          icon: ICONS.file,
        },
      ],
    },

    {
      subheader: 'Analytics',
      items: [
        {
          title: 'Overview',
          path: PATH_DASHBOARD.general.overView,
          icon: ICONS.overview,
        },
        {
          title: 'Scan cars',
          path: PATH_DASHBOARD.general.analytics,
          icon: ICONS.analytics,
        },
        {
          title: 'detailed',
          path: PATH_DASHBOARD.general.parkingFineSystem,
          icon: ICONS.folder,
        },
        // {
        //   title: 'Vehicle Monitor',
        //   path: PATH_DASHBOARD.general.vehicleMonitor,
        //   icon: ICONS.monitor,
        // },
      ],
    },
  ];
  if ([ROLES.ADMIN, ROLES.SHIFT_MANAGER].includes(user?.role)) {
    navigations.splice(1, 0, ...AdminSiftManagersSubHeaders);
  }
  if (canReadAuditTrailList) {
    navigations.push({
      subheader: 'Audit',
      items: [
        {
          title: 'Audit Trail',
          path: PATH_DASHBOARD.auditTrail.auditTrail,
          icon: ICONS.blank,
        },
      ],
    });
  }

  return navigations;
}

export default useNavConfig;
